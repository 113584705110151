.manage-location th:nth-child(1) {
  width: 35%;
}

.manage-location th:nth-child(2) {
  width: 35%;
}

.manageLocationTableWrapper {
  max-height: calc(100vh - 18vw);
  overflow: auto;
}

/* Manage contact person */
.manage-contact-person th:nth-child(1) {
  width: 8vw !important;
  max-width: 8vw;
}

.manage-contact-person th:nth-child(2),
.manage-contact-person th:nth-child(3),
.manage-contact-person th:nth-child(4){
  width: 15%;
}

.manage-contact-person th:nth-child(5) {
  width: 20%;
}

.manage-contact-person th:nth-child(6) {
  width: 10%;
}