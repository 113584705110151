/* .manage-function-search label,
.function-level label,
.function-level .height-20 {
  display: none;
} */

.link-function-table td:nth-child(1) {
  width: 15%;
}
.link-function-table td:nth-child(2) {
  width: 70%;
}
.link-function-table td:nth-child(3) {
  width: 15%;
}
.link-function-table td {
  padding: 1rem 0.5rem;
}
.manage-function-profile-table th:nth-child(1)
{
  width: 10%;
}
.manage-function-profile-table th:nth-child(2)
{
  width: 35%;
}
.manage-function-profile-table th:nth-child(3)
{
  width: 45%;
}