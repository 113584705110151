.mainWrapper {
  min-height: calc(100vh - 3.8vw);
}

.access-denied-code {
  color: var(--color-dark-pink);
  font-family: var(--font-fraunces-semibold-italic);
  font-size: var(--size-160px);
  line-height: 13vh;
}

.access-denied-title {
  font-family: var(--font-roobert-regular);
  font-size: var(--size-60px);
}

.access-denied-content {
  font-size: var(--size-32px);
}

.page-not-found-back-link:hover {
  text-decoration: none !important;
}


.page-not-found-back-link{
  width: fit-content !important;
  margin: auto;
}