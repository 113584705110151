.react-datepicker-wrapper {
  width: 100%;
}

.calender-icon {
  top: 0.3vw !important;
  right: 0.5vw;
}

.calender-icon svg {
  color: var(--color-dark-skyblue);
  width: 1.25vw;
  height: 1.25vw;
}

/* static/css/calender.css */
.custom-datepicker-popper {
  z-index: 2000;
  /* Adjust the z-index value as needed */
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  overflow: visible;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 1vw !important;
}