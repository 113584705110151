.manage-users th:first-child,
.manage-users th:nth-child(2),
.manage-users th:nth-child(3),
.manage-users th:nth-child(5),
.manage-users th:nth-child(6) {
  width: 12%;
}

.manage-users th:nth-child(4) {
  width: 18%;
}

.manage-users th:nth-child(7) {
  width: 8%;
}
.status-icon {
  width: 0.8vw;
  height: 0.8vw;
  margin: 0;
  margin-left: 0.6vw;
}
