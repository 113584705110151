.logo-width {
  width: 13vw;
}

.login-title {
  color: var(--primaryBrandColor);
  font-family: var(--font-roobert-semibold);
  font-size: var(--size-25px);
}

.dot-arrow {
  width: 2.604vw;
  transform: rotate(12deg);
  top: -0.781vw;
  right: -1.979vw;
}

.eye-icon {
  top: 0.5vw;
  right: 1vw;
}

.eye-icon img {
  width: 1vw;
}

.login-bg .form-button svg {
  padding-left: 0.4vw;
}

.login-bg {
  background-color: var(--budget-bg);
}
