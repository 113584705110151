.vacancy-step-tabs-absolute {
  top: -0.5vw;
  z-index: 99;
}

.vacancyAddLocation .modal-content {
  width: 50vw;
  margin: auto;
}

.wage-wrapper .input-group-text {
  height: 2.25vw !important;
  right: 1.05vw;
  top: 0.05vw;
  background-color: var(--sideBarBgColor);
  border: 0.052vw solid var(--sideBarBgColor);
  width: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
  border-top-right-radius: 0.4vw !important;
  border-bottom-right-radius: 0.4vw !important;
}

.wage-wrapper label,
.wage-wrapper .height-20 {
  display: none;
}

.wage-wrapper {
  padding-left: 1vw !important;
  padding-right: 1vw !important;
}

.vacancyCornerLeftSection {
  background-color: var(--lightGray);
  padding: 1vw;
}

.personal-info {
  font-family: var(--font-roobert-semibold);
  font-size: var(--size-16px);
}

.vacancySubTitle {
  color: var(--color-dark-pink);
  font-size: var(--size-20px);
  font-family: var(--font-roobert-semibold);
}

.vacancyCornerLeftSection .vacancyContactPerson {
  font-size: var(--size-18px);
}

.form-height-vacancy-overview {
  min-height: calc(100vh - 13vw);
}

.locationPopup .form-border {
  padding: 0 !important;
}

.locationPopup .modal-dialog {
  min-width: 60vw !important;
  max-width: 60vw !important;
}

.wageStarter {
  padding-left: 0.2vw !important;
}