.fleetEmployeeOverview .tableMainWrapper {
  margin-top: 0 !important;
}

.addCarWrapper .dynamicForm {
  padding: 0.5vw !important;
}

.manageCarSearchCol .form-control {
  min-height: 2vw !important;
  padding: 0.2vw !important;
}

.manageCarSearchCol .multiSelectDropdown__control {
  min-height: 1vw !important;
}

.manageCarSearchCol .calender-icon svg {
  width: 1vw !important;
  height: 1vw !important;
}

.manageCarSearchCol {
  grid-template-columns: repeat(10, 1fr);
}

.manageMaterialSearch {
  grid-template-columns: repeat(11, 1fr);
}

.manageClothesSearchCol,
.manageSupplierSearchCol {
  grid-template-columns: repeat(6, 1fr);
}

.manageFleetLocationSearchCol {
  grid-template-columns: repeat(4, 1fr);
}

.manageCarSearchCol .close-button {
  height: 2vw !important;
  padding: 0.1vw !important;
  min-height: 2vw !important;
}

.manageCarSearchCol .p-multiselect-label-container {
  height: auto !important;
}

.manageCarHeight {
  height: calc(100vh - 20vw);
  overflow: auto;
}

.manageCarHeightWIthoutSearch {
  height: calc(100vh - 17vw);
  overflow: auto;
}

.fleetModalHeader {
  padding-top: 1.5vw !important;
  padding-bottom: 0.25vw !important;
}

.manageMaterial,
.manageSupplierHeight,
.manageClientHeight,
.manageClothesHeight,
.managePurchaseOrderHeight,
.managePayrollHeight {
  height: calc(100vh - 17vw);
  overflow: auto;
}

.manageCarSearchCol .p-multiselect .p-multiselect-label {
  padding: 0 !important;
}

.manageCarSearchCol .multiSelectDropdown__input-container, .manageCarSearchCol .multiSelectDropdown__placeholder {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Manage material */
.manageMaterial th:nth-child(1),
.manageMaterial th:nth-child(7),
.manageMaterial th:nth-child(8),
.manageMaterial th:nth-child(9) {
  width: 10%;
}

.manageMaterial th:nth-child(2),
.manageMaterial th:nth-child(6) {
  width: 14%;
}

.manageMaterial th:nth-child(3),
.manageMaterial th:nth-child(4),
.manageMaterial th:nth-child(5) {
  width: 6.5%;
}

.manageMaterial th:nth-child(10) {
  width: 5%;
}


/* Manage clothes */
.manageClothesHeight th:nth-child(1),
.manageClothesHeight th:nth-child(3),
.manageClothesHeight th:nth-child(4) {
  width: 13%;
}

.manageClothesHeight th:nth-child(2) {
  width: 20%;
}

.manageClothesHeight th:nth-child(5) {
  width: 8%;
}

/* Manage supplier */

.manageSupplier th,
.manage-clients th,
.manage-purchaseOrder th {
  width: calc(100% /6);
}

.manage-fleetLocation th {
  width: calc(100% /4);
}