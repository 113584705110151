.agreement-step-tabs {
  position: sticky;
  top: 93px;
  background-color: var(--color-white);
  z-index: 998;
}

.agreement-step-tabs .nav-link {
  border: 1px solid var(--color-dark-skyblue);
  color: var(--color-dark-skyblue) !important;
  font-family: var(--font-vfutura-medium) !important;
  min-height: 7rem;
  flex-basis: 0;
  flex-grow: 1;
  padding-top: 1.5rem;
}

.agreement-step-tabs .nav-link.active,
.agreement-step-tabs .nav-link:hover {
  background-color: var(--color-dark-skyblue) !important;
  color: var(--color-white) !important;
  border: 1px solid var(--color-dark-skyblue) !important;
  font-family: var(--font-vfutura-medium) !important;
}

.agreement-step-tabs .nav-link.tab-inactive {
  color: var(--color-black) !important;
  border: 1px solid var(--color-black) !important;
  font-family: var(--font-vfutura-medium) !important;
}

.agreement-tabs-title {
  position: sticky;
  top: 236px;
  background-color: var(--color-white);
  z-index: 998;
}

.agreement-step-tabs .nav-link:last-child {
  margin-right: 0px !important;
}

.agreement-step-tabs .nav-link.error {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border: 4px solid var(--color-red) !important;
}

.agreement-step-tabs .nav-link .steps {
  font-size: var(--font-18px);
  background-color: var(--color-white);
  color: var(--color-dark-skyblue);
  border: 1px solid var(--color-dark-skyblue);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.agreement-step-tabs .nav-link.error {
  background-color: var(--color-white) !important;
  color: var(--color-red) !important;
  border: 4px solid var(--color-red) !important;
}

.agreement-step-tabs .nav-link.error .steps {
  border: 2px solid var(--color-red);
  color: var(--color-red);
}

.agreement-step-tabs .nav-link.active .steps,
.agreement-step-tabs .nav-link:hover .steps {
  font-size: var(--font-18px);
  background-color: var(--color-white);
  border: 1px solid var(--color-dark-skyblue);
  color: var(--color-dark-skyblue);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.agreement-step-tabs .nav-link.tab-inactive .steps {
  font-size: var(--font-18px);
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  color: var(--color-black);
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.agreement-step-tabs .nav-link.error .steps {
  border: 2px solid var(--color-red);
  color: var(--color-red);
}

.agreement-step-tabs .step-title {
  font-size: var(--font-18px);
  min-height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agreement-step-number {
  min-height: 2rem;
  display: flex;
  align-content: flex-end;
}

@media screen and (min-width: 0px) and (max-width: 991px) {
  .agreement-step-tabs .nav-link {
    display: none;
  }

  .agreement-step-tabs .nav-link.active {
    display: block;
  }

  .manage-candidates td:nth-child(6) {
    display: none;
  }
}

.content-title {
  font-family: var(--font-vfutura-medium);
  font-size: var(--font-20px);
}

.slide-in-modal {
  transform: translateY(-100%);
  transition: transform 1s ease-in-out;
  backdrop-filter: blur(2px);
  pointer-events: none;
}

.slide-in-modal.show {
  transform: translateY(0);
}

.general-agreement-table th:nth-child(1),
.general-agreement-table th:nth-child(2) {
  width: 40%;
}

.composition-co-efficient-table th:nth-child(1) {
  width: 35%;
}

.composition-co-efficient-table th:nth-child(2),
.composition-co-efficient-table th:nth-child(3) {
  width: 15%;
}

.composition-co-efficient-table th:nth-child(4) {
  width: 10%;
}

.composition-co-efficient-table th:nth-child(5) {
  width: 25%;
}

.composition-co-efficient-table td:nth-child(4) .height-20,
.composition-co-efficient-table td:nth-child(5) .height-20 {
  display: none;
}

.composition-co-efficient-table td:nth-child(2) input,
.composition-co-efficient-table td:nth-child(3) {
  margin-right: 0px !important;
}

.border-negotiable input {
  border: 1px solid var(--color-light-pink) !important;
}

.composition-co-efficient-table .form-check-input:checked[type='radio'] {
  background-image: url('../../../../../../static/images/circleTick.svg') !important;
}

.composition-co-efficient-table .form-check-input:checked {
  background-color: var(--color-white) !important;
  border: none !important;
}

.composition-co-efficient-table .form-check-input[type='radio'],
.composition-co-efficient-table .form-check-input:checked[type='radio'] {
  width: 22px;
  height: 22px;
}

.agreement-step-tabs-absolute {
  top: 0.625vw;
  z-index: 99;
}

.cooperation-billing-table th:nth-child(1),
.cooperation-billing-table th:nth-child(2),
.cooperation-billing-table th:nth-child(3),
.cooperation-billing-table th:nth-child(4) {
  width: 12%;
}

.cooperation-billing-table th:last-child {
  width: 10%;
}

.cooperation-billing-table th:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:last-child) {
  width: 10%;
}

.agreement-profile .autoCompleteInput {
  border: 0 !important;
  margin-bottom: 0 !important;
}

.employee-type label,
.employee-type .height-20,
.coeffDataDynamic label,
.coeffDataDynamic .height-20 {
  display: none;
}

td.employee-type,
td.coeffDataDynamic,
td.agreement-profile {
  position: relative;
  padding: 0 !important;
}

.cooperation-billing-table .field-shadow {
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.coeffDataDynamic .col-md-12 {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.coeffDataDynamic .col-md-12 .form-control {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}

.coeffDataDynamic .col-md-12 .form-control:focus,
.employee-type .multiSelectDropdown__control--is-focused,
.agreement-profile .multiSelectDropdown__control--is-focused {
  border: 0.052vw solid var(--color-dark-purple) !important;
  border-radius: 0vw !important;
}

.euroSignAgreement {
  align-items: center;
  background-color: var(--sideBarBgColor);
  display: flex;
  justify-content: center;
  width: 2vw;
  margin-left: 0vw !important;
  height: 2.344vw;
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw, rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
}

.agreement-wage-elements .form-control:disabled {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.remarksTextArea .height-20 {
  display: none;
}

.agreement-billing-tab thead::after {
  display: none;
}