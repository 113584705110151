.planning_cal_btn {
  color: var(--color-dark-purple) !important;
  font-size: 1.3vw;
}

.planning_cal_btn:hover {
  color: var(--primaryBrandColor) !important;
}

.planning_year .col-4:nth-child(3) {
  padding-right: 0;
}

.e-appointment {
  position: relative;
  border-radius: 0 !important;
  /* border: 0px !important; */
}

.timesheethovermain {
  position: relative;
  background: var(--primaryBrandColor) !important;
  border-radius: 0 !important;
  padding: 0.2vw !important;
}

.e-appointment:hover .e-planned-details {
  display: block !important;
  color: var(--color-dark-purple);
}

.timesheethovermain:hover .timesheethoverchild {
  display: block !important;
  color: var(--color-dark-purple);
}

.timesheethoverchild {
  position: absolute;
  height: max-content;
  width: max-content;
  right: 80%;
  z-index: 99;
  bottom: 70%;
  background: var(--sideBarBgColor) !important;
  padding: 0.5vw;
  border-radius: 0.5vw;
  display: none;
}

.e-planned-details {
  display: none;
}

.e-planned-details {
  position: absolute;
  height: max-content;
  width: max-content;
  z-index: 99;
  background: var(--color-white) !important;
  padding: 0.5vw;
  border-radius: 0.5vw;
}


.weeklyPlaniingTable td,
.planningLeftColumn td {
  padding: 0 !important;
  outline: 0.052vw solid var(--color-white) !important;
}

.sidebar_bg th {
  background-color: var(--sideBarBgColor);
  min-height: 0.5vw !important;
  max-height: 0.5vw !important;
  height: 0.5vw !important;
  line-height: 0.5vw !important;
}

.planningHeight {
  max-height: calc(100vh - 10vw);
  overflow: auto;
}

.planningHeight thead {
  position: sticky;
  top: 0vw;
  background-color: var(--color-white);
  z-index: 99;
  height: 4vw;
}

.planningHeight .planningLeftColumn {
  position: relative;
  top: -0.01vw;
  background-color: var(--color-white)
}

.planningCostSticky {
  position: sticky;
  top: 5.3vw;
  background-color: var(--color-white);
  z-index: 99;
}

.yearPlanningTable .e-inner-wrap>div {
  width: 0.5vw;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.7vw !important
}

.planningHeight tbody td {
  font-size: 0.7vw !important;
}

.activeclasssssss {
  border: 0.1vw solid red;
  font-weight: bold;
  color: red !important;
}

.planningRightSection th,
.planningRightSection td {
  min-width: 2.5vw;
  max-width: 2.5vw;
  width: 2.5vw;
  outline: 0.052vw solid var(--color-white);
}

.planningRightSection.week_8 th,
.planningRightSection.week_8 td {
  min-width: 2.87vw;
  max-width: 2.87vw;
  width: 2.87vw;
}

.planningTableHeader th {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.colFixed {
  position: sticky;
  left: 0;
  background: white;
  z-index: 999;
}

.planningTableHeaderFilter th {
  background-color: transparent;
}

.planningTableHeaderFilter th span {
  background-color: var(--sideBarBgColor);
  width: 2vw;
  display: block;
  margin-left: auto;
}


.planningTableHeaderFilter th {
  padding: 0;
  line-height: 1.7vw !important;
  height: 1.7vw !important;
}

.planningRightSection th:last-child,
.planningRightSection td:last-child {
  position: sticky;
  right: 0vw;
  min-width: 2vw;
  max-width: 2vw;
  width: 1vw;
  text-align: center;
  padding: 0.5vw !important;
}

.planningRightSection .weeklyPlaniingTable:first-child {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  z-index: 998;
}

.bottom {
  top: 100% !important;
}

.top {
  bottom: 100% !important;
}

.left {
  right: 100% !important;
}

.right {
  left: 100% !important;
}

.planningPopupContent:hover {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
}

.planningPopupContent {
  padding: 0.2vw 0.5vw !important;
}

.planningHeight .border-white {
  outline: 0.052vw solid var(--color-white) !important;
  font-size: 0.7vw !important;
}

.planningPopupModal .form-button {
  min-width: 7vw !important;
}


.e-inner-wrap .textEllipsis {
  text-overflow: clip !important;
  font-size: 0.65vw !important;
}

.planningMonthHeaderContent {
  padding: 0.25vw !important;
}

.planningLeftColumn .textEllipsis {
  font-size: 0.7vw !important;
  text-align: left;
}

.planningFilters .p-multiselect-label,
.planningFilters input {
  font-size: 0.7vw !important;
}