body {
  font-size: var(--size-16px) !important;
  font-family: var( --font-roobert-regular) !important;
  background-color: var(--sideBarBgColor);
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

:root {
  /* Color */
  --color-lowlight-skyblue: #f7fdff;
  --color-dark-skyblue: #00a5ce;
  --color-light-skyblue: #87D2E5;
  --primaryBrandColor: #00a5ce;
  --color-dark-purple: #00182c;
  --color-black: #000;
  --color-white: #fff;
  --sideBarBgColor: #e8f8fb;
  --color-baby-pink: #fbb0d3;
  --budget-bg: #f6f6f8;
  --color-red: #dc381f;
  --header-bg-table-color: #e3e6e8;
  --action-icon-color: #999c9f;
  --tiles-bg-color: #4c4d550f;
  --modal-border-color: #0006;
  --header-border: #999c9f63;
  --pagination-color: #a2a2a2;

  /* Font size */
  --size-14px: 0.729vw;
  --size-15px: 0.781vw;
  --size-16px: 0.833vw;
  --size-18px: 0.938vw;
  --size-20px: 1.042vw;
  --size-22px: 1.146vw;
  --size-24px: 1.25vw;
  --size-25px: 1.302vw;
  --size-26px: 1.354vw;
  --size-28px: 1.458vw;
  --size-30px: 1.563vw;
  --size-32px: 1.667vw;
  --size-34px: 1.771vw;
  --size-38px: 1.979vw;
  --size-36px: 1.875vw;
  --size-40px: 2.083vw;
  --size-46px: 2.396vw;
  --size-44px: 2.292vw;
  --size-50px: 2.604vw;
  --size-60px: 2.604vw;
  --size-160px: 8.333vw;

  /* Font family */
  --font-nasalization-normal: "nasalization";
  --font-vfutura-heavy: "vfutura-heavy";
  --font-vfutura-light: "vfutura-light";
  --font-vfutura-medium: "vfutura-medium";
  --font-roobert-bold: "roobert-bold";
  --font-roobert-regular: "roobert-regular";
  --font-roobert-semibold: "roobert-semibold";
  --font-fraunces-medium-italic: "fraunces-medium-italic";
  --font-fraunces-semibold-italic: "fraunces-semibold-italic";
   --font-roobert-medium: "calbri-bold";

  /* Height */
  --height-50px: 3.125rem;
}

@font-face {
  font-family: "vfutura-heavy";
  src: url(../fonts/VFutura/VFutura-Heavy.otf);
}

@font-face {
  font-family: "vfutura-light";
  src: url(../fonts/VFutura/VFutura-Light.otf);
}

@font-face {
  font-family: "vfutura-medium";
  src: url(../fonts/VFutura/VFutura-Medium.otf);
}

@font-face {
  font-family: "roobert-bold";
  src: url(../fonts/Roobert/Roobert-Bold.otf);
}

@font-face {
  font-family: "roobert-regular";
  src: url(../fonts/Roobert/Roobert-Regular.otf);
}

@font-face {
  font-family: "roobert-semibold";
  src: url(../fonts/Roobert/Roobert-SemiBold.otf);
}

@font-face {
  font-family: "fraunces-semibold-italic";
  src: url(../fonts/Fraunces/Fraunces_144pt-SemiBoldItalic.ttf);
}

@font-face {
  font-family: "fraunces-medium-italic";
  src: url(../fonts/Fraunces/Fraunces_9pt_Soft-BlackItalic.ttf);
}

@font-face {
  font-family: "nasalization";
  src: url(../fonts/nasalization/nasalization-rg.otf) format("opentype");
}

@font-face {
  font-family: "calbri-regular";
  src: url("../fonts/calibri/calibri-regular.ttf");
}

@font-face {
  font-family: "calbri-bold";
  src: url("../fonts/calibri/calibri-bold.ttf");
}

.color-light-pink {
  color: var(--color-light-pink);
}

.color-dark-skyblue {
  color: var(--primaryBrandColor);
}

.primary_bg {
  background: var(--primaryBrandColor) !important;
}

.low-light-skyblue-bg {
  background: var(--color-lowlight-skyblue) !important;
}

.sidebar_bg {
  background: var(--sideBarBgColor);
}

.bg-lightskyblue {
  background: var(--sideBarBgColor);
}

.right-25 {
  right: 25%;
}

.w-13 {
  width: 13%;
}

.h-45px {
  height: 45px;
}

.z-6 {
  z-index: 6;
}

.font-CalbriBold {
  font-family: var( --font-roobert-medium);
}

.frauncesItalic {
  font-family: var( --font-fraunces-semibold-italic);
}