.marginCalculatorLeftSection,
.marginCalculatorRightSection {
  height: calc(100vh - 9vw) !important;
}

.marginCalculatorLeftSection thead {
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--budget-bg);
}

.marginCalculatorLeftSection thead tr th {
  outline: 0.052vw solid var(--color-white);
  text-align: center;
  font-family: var(--font-roobert-bold);
  border: 0.052vw solid var(--color-white) !important;
  font-size: 0.75vw !important;
}

.marginCalculatorLeftSection th:first-child {
  width: 10%;
}

.marginCalculatorLeftSection th:nth-child(2) {
  width: 12%;
}

.marginCalculatorLeftSection th:nth-child(3),
.marginCalculatorLeftSection th:nth-child(4) {
  width: 16%;
}

.marginCalculatorLeftSection th:nth-child(5),
.marginCalculatorLeftSection th:nth-child(6),
.marginCalculatorLeftSection th:nth-child(7),
.marginCalculatorLeftSection th:nth-child(8) {
  width: 8%;
}

.marginCalculatorLeftSection td:nth-child(2) {
  text-align: center;
}

.marginCalculatorTime input:focus {
  border: 0.052vw solid var(--color-dark-skyblue) !important;
}

.marginCalculatorLeftSection td {
  vertical-align: middle !important;
  padding: 0.05vw !important;
  font-size: 0.75vw !important;
}

.marginCalculatorLeftSection *{
  font-size: 0.75vw !important;
}
.marginCalculatorRightSection *:not(.page-title)  {
  font-size: 0.85vw !important;
}

.marginCalculatorLeftSection .border-danger {
  border: 0.052vw solid transparent !important;
  outline: 0.052vw solid var(--color-red) !important;
}

.marginAllowanceField .height-20 {
  display: none !important;
}

.marginAllowanceField input {
  border-radius: 0vw !important;
  box-shadow: none !important;
  width: 100%;
  margin: auto;
  border: 0.052vw solid #dee2e6 !important;
  height: 1.5vw !important;
  padding: 0.25vw !important;
  min-height: 1.5vw !important;
  text-align: right !important;
}

.marginAllowanceField input:focus {
  border: 0.052vw solid var(--color-dark-skyblue) !important;
}

.table td.allowanceWithoutPause {
  padding: 0 !important;
}

.table td.allowanceWithoutPause input {
  border-radius: 0 !important;
  box-shadow: none !important;
  text-align: right;
  min-height: 1.5vw !important;
  height: 1.5vw !important;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1vw;
}

.marginCalculatorRightSection .accordion-body {
  padding: 0 !important;
}

.marginCalculatorRightSection .accordion-item {
  margin-bottom: 0.5vw !important;
}

.marginCalculatorLeftSection td {
  border: 0.2vw solid var(--color-white) !important;
  background-color: var(--budget-bg) !important;
}

.marginCalculatorLeftSection td input {
  background-color: var(--budget-bg) !important;
}

.marginCalculatorRightSection .height-20 {
  margin-bottom: 0.05vw !important;
  margin-top: 0.05vw !important;
}