.manageTodosFollowUps th:first-child {
  width: 7vw !important;
  min-width: 7vw !important;
  max-width: 7vw !important;
}

.manageTodosFollowUps th:nth-child(2),
.manageTodosFollowUps th:nth-child(3),
.manageTodosFollowUps th:nth-child(5) ,
.manageTodosFollowUps th:nth-child(6),
.manageTodosFollowUps th:nth-child(8) {
  width: 10%;
}

.manageTodosFollowUps th:nth-child(4),
.manageTodosFollowUps th:nth-child(7) {
  width: 15%;
} 
