.active-tab {
  text-decoration: underline !important;
  color: var(--primaryBrandColor);
}

.link-text {
  font-size: var(--size-20px) !important;
}

.questionName {
  font-size: var(--size-18px);
  color: var(--color-dark-skyblue);
  margin-bottom: 0.25vw !important;
}

.questionOptions {
  padding-left: 1.9rem;
}

.manage-questions-table th:nth-child(1) {
  width: 60%;
}

.question-radio-btn {
  left: 15%;
  top: 0;
  transform: translate(0%, -0%);
}

.checkbox-option {
  display: inline-block;
  margin-right: 10px;
}

.not-applicable-action {
  background-color: var(--header-bg-table-color);
  border: 0 !important;
  padding: 0.1vw 0.5vw !important;
}

.not-applicable-action:hover {
  background-color: var(--primaryBrandColor);
  color: var(--color-white);
}

.screening-checkbox p {
  margin-bottom: 0 !important;
}

.screening-checkbox input[type='checkbox'] {
  margin-top: 4px !important;
}

.screening-checkbox input[type='radio'] {
  margin-top: 6px !important;
}

.multipleChoiceQns .ck-editor__editable_inline,
.radioFieldQns .ck-editor__editable_inline {
  height: 4vw !important;
}

.question-tabs button:hover,
.question-tabs button.active {
  text-decoration: underline !important;
  color: var(--primaryBrandColor);
  text-underline-offset: 0.1vw;
}

.question-tabs button {
  font-size: var(--size-18px);
  font-family: var(--font-roobert-medium);
}

.add-btn-position {
  top: -20px !important;
}


.manageQuestionHeight th {
  width: calc(100% /5);
}