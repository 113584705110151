.manage-projects th:first-child {
  width: 7vw;
  max-width: 7vw;
  min-width: 7vw;
}

.manage-projects th:nth-child(2),
.manage-projects th:nth-child(3),
.manage-projects th:nth-child(4) {
  width: 14%;
}

.manage-projects th:nth-child(5),
.manage-projects th:nth-child(6) {
  width: 8%;
}

.manage-projects th:nth-child(7),
.manage-projects th:nth-child(8) {
  width: 10%;
}

.projectTabs .createStepBtn {
  min-width: 9vw;
  z-index: 9;
}

.projectTitle {
  padding-bottom: 2vw;
}

.projectTabs.project-form.company-step-tabs-absolute {
  top: 7.2vw !important;
}

.projectTabs.company-step-tabs-absolute {
  top: 2.5vw !important;
}

.multiTabsForm.company-step-tabs-absolute {
  top: 1.2vw;
}

/*Manage cometence page*/
.Manage-competence th:first-child,
.Manage-competence td:first-child {
  width: 60%;
}

td.shiftInputCell {
  padding: 0 0vw !important;
  height: 2vw;
}

td.shiftInputCell input:focus,
.shiftTimingshiftInputCell input:focus {
  border: 0.052vw solid var(--primaryBrandColor) !important;
}

td.shiftInputCell input,
.shiftTimingshiftInputCell input {
  font-size: 0.85vw !important;
  border: 0.052vw solid var(--color-white) !important;
}

.shiftTimingshiftInputCell input {
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
  border-radius: 0.5vw;
  min-height: 2.344vw !important;
}

.projectsGeneral .form-border {
  background-color: var(--color-white) !important;
  padding-bottom: 1vw !important;
  margin-bottom: 2vw !important;
}

.manage-projects .manageProjectTableHeight {
  height: calc(100vh - 16vw) !important;
  overflow: auto;
}

.projectName {
  font-size: 1.5vw !important;
}

.cardNameText {
  margin-bottom: 0 !important;
  line-height: 2vw !important;
}

.Matching-project th:first-child,
.Matching-project th:nth-child(3) {
  width: 20%;
}

.Matching-project th:nth-child(4),
.Matching-project th:nth-child(2),
.Matching-project th:nth-child(5) {
  width: 10%;
}

.Matching-project th:nth-child(6) {
  width: 15%;
}

.Matching-project {
  max-height: calc(100vh - 19vw);
  overflow: auto;
}

.matchinProjectFilter th:nth-child(1) {
  width: 20%;
}

.matchinProjectFilter th:nth-child(2) {
  width: 20%;
}

.matchinProjectFilter th:nth-child(3) {
  width: 20%;
}

.competencesModal .modal-body .accordion {
  max-height: calc(100vh - 15vw);
  overflow: auto;
}

.addProjectCompetencesPopup .modal-body,
.addProjectQuestionPopup .modal-body {
  padding: 0 0.15vw !important;
}
.addProjectQuestionPopup .modal-dialog,
.addContactPersonsModal .modal-dialog,
.generalAddLocationPopup .modal-dialog {
  min-width: 75% !important;
  width: 75% !important;
  max-width: 75% !important;
}

.addProjectQuestionPopup .form-border,
.addProjectCompetencesPopup .modal-body{
  max-height: calc(100vh - 18vw) !important;
  overflow: auto;
}
.projectAccordionIcon .table-action-btn {
  margin-top: 0.21vw !important;
}

.addContactPersonsModal .modal-body,
.generalAddLocationPopup .modal-body  {
  max-height: calc(100vh - 15vw) !important;
  overflow: auto;
}

.generalAddLocationPopup .form-border {
  padding: 0 !important;
}


.generalAddLocationPopup .modal-body,
.generalAddLocationPopup .modal-header {
  padding: 1vw !important;
}