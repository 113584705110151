/* Overlay */
.fullscreen-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.26vw);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.fullscreen-loading-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.3);
  z-index: -1;
}

.height-20 {
  min-height: 0.8vw;
}

.page-width {
  width: calc(100vw - 6vw);
  margin-left: 4.8vw;
  /* Use a numeric starting value */
  transition: margin-left 1s ease;
  /* Adjust duration and timing function as needed */
}

.tableMainWrapper {
  margin-top: 1.25vw;
}

.tableSection {
  border: 0.052vw solid #e6e9ec;
  border-radius: 0.5vw;
  padding: 1vw 1.2vw 0.5vw;
}

.tableSection thead {
  position: sticky;
  top: 0;
  z-index: 99;
}

.tableSection .filterTable thead {
  z-index: 100;
}

.tableSection .TableHeight {
  height: calc(100vh - 16.25vw);
  overflow: auto;
}

.tableSection .TableHeightWithoutSearch {
  height: calc(100vh - 9vw);
  overflow: auto;
}

.tableSection .tableHeightWithoutBtn {
  height: calc(100vh - 19vw);
  overflow: auto;
}

.tableSection .tableWithSearchBar {
  height: calc(100vh - 16.5vw);
  overflow: auto;
}

.parrollTableWrapper {
  height: calc(100vh - 13.25vw);
  overflow: auto;
}

.tableWithSearchBar td {
  padding: 0.3vw !important;
}

.tableSection td {
  vertical-align: middle;
}

.ManageCreateBtn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1.05vw;
}

.ManageCreateBtn .form-button {
  min-height: 1vw !important;
  padding: 0.25vw 1vw !important;
}

.ManageCreateBtn a:hover {
  text-decoration: none !important;
}

.TableHeader,
.p-treetable-wrapper thead tr {
  background-color: var(--budget-bg);
  vertical-align: middle;
}

.TableHeader th,
.p-treetable-wrapper thead tr th {
  background-color: initial !important;
  color: var(--color-dark-purple) !important;
  font-weight: 400 !important;
  padding: 0.3vw 0.2vw !important;
  font-size: 0.825vw !important;
  font-family: var(--font-roobert-regular);
}

.tableSection td,
.p-treetable-wrapper thead tr td {
  padding: 0.3vw 0.2vw !important;
}

.p-treetable-wrapper tbody tr td {
  font-size: var(--size-14px) !important;
  color: var(--color-dark-purple) !important;
  transition: color 0.3s ease;
  font-family: var(--font-roobert-regular) !important;
}

/* Pagination */

.pagination .page-num {
  min-width: 2.5vw;
  color: var(--color-dark-purple);
  font-size: 1vw !important;
  padding: 0.25vw 1vw !important;
}

.pagination .previous-btn.disabled,
.pagination .next-btn.disabled {
  background-color: var(--color-dark-purple) !important;
}

.pagination .btn svg path {
  fill: var(--color-white) !important;
}

.pagination .btn.active,
.pagination .previous-btn,
.pagination .next-btn {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
  border-radius: 50% !important;
  width: 2.083vw;
  height: 2.083vw;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .page-active,
.pagination .page-num:hover {
  color: var(--primaryBrandColor);
}

.pagination svg {
  width: 1vw !important;
  height: 1vw !important;
}

.form-border {
  background-color: #e7f6f975;
  padding: 1vw;
  border-radius: 1vw;
}

/* Create Tabs sticky */
.createTabsSticky {
  background-color: var(--color-white);
  top: 5.5vw;
  transition: all 0.5s ease-in-out;
  z-index: 999;
}

.multiTabsForm.createTabsSticky {
  background-color: var(--color-white);
  top: 4.5vw;
  transition: all 0.5s ease-in-out;
  z-index: 997;
}

.createFormTabs .createStepBtn,
.fleetAndMaterialBtn {
  background-color: var(--sideBarBgColor);
  border: 0.052vw solid var(--sideBarBgColor) !important;
  box-shadow: 0 0.104vw 0.104vw 0 #63636333;
  color: var(--primaryBrandColor) !important;
  font-size: var(--size-16px);
  flex-basis: 0;
  flex-grow: 1;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 0.5vw;
  padding: 0.3vw;
  min-width: 8vw;
}

.fleetCreateFormTabs.createFormTabs .createStepBtn {
  padding: 0.25vw !important;
}

.createFormTabs .createStepBtn.active,
.createFormTabs .createStepBtn:hover,
.createFormTabs .createStepBtn.active.error,
.fleetAndMaterialBtn:hover {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
  border: 0.052vw solid var(--primaryBrandColor) !important;
  text-decoration: none !important;
}

.createFormTabs .createStepBtn.error {
  background-color: var(--color-white) !important;
  border: 0.052vw solid var(--color-red) !important;
  color: var(--color-red) !important;
}

.createFormTabs {
  padding: 1vw 1.25vw;
  z-index: 100;
}

.tab-title {
  color: var(--color-dark-purple);
  font-size: var(--size-22px);
  padding-top: 0.677vw;
  padding-bottom: 1.302vw;
  font-family: var(--font-roobert-semibold);
}

.tab-subtitle {
  color: var(--color-dark-purple) !important;
  font-size: var(--size-22px) !important;
  font-family: var(--font-roobert-bold) !important;
  padding-bottom: 1.302vw;
}

.cursor-pointer {
  cursor: pointer !important;
}

.table td,
.table td a {
  font-size: var(--size-14px);
  color: var(--color-dark-purple);
  transition: color 0.3s ease;
}

.table td {
  padding-top: 0.15vw !important;
  padding-bottom: 0.15vw !important;
}

.table td a:hover {
  color: var(--color-dark-pink);
}

::-webkit-scrollbar {
  width: 0.25vw;
  height: 0.3vw;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-skyblue);
  border-radius: 0.5vw;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.25vw var(--pagination-color);
  border-radius: 0.5vw;
}

/* Modal */

.modal-title {
  color: var(--color-dark-skyblue) !important;
  font-size: var(--size-20px) !important;
}

.modal .btn-close:focus {
  box-shadow: none !important;
}

.modal-border {
  border-bottom: 0.052vw solid var(--modal-border-color) !important;
}

.modal-content {
  border-radius: 0.521vw !important;
  padding: 0.417vw 2.5vw !important;
  background-color: var(--sideBarBgColor) !important;
  border: 0vw !important;
}

.modal-body {
  font-size: var(--size-18px);
}

.modal .btn {
  min-width: 3.333vw;
  font-size: var(--size-16px) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vw !important;
}

.modal .btn-close {
  --bs-btn-close-bg: none !important;
  background: var(--primaryBrandColor) center/1em auto no-repeat !important;
  background-image: url('../images/ModalClose.svg') !important;
  left: 50%;
  opacity: 1;
  position: absolute;
  top: -0.821vw;
  min-width: 1vw;
  height: 1vw;
  border-radius: 0.5vw !important;
}

.modal-backdrop {
  background-color: #fff !important;
  --bs-backdrop-opacity: 0.75 !important;
}

.exportPopup .react-datepicker__month .react-datepicker__month-text {
  width: 3vw !important;
}

.exportPopup .react-datepicker__navigation {
  top: 0.5vw !important;
}

.table-top-buttons-block a:hover {
  text-decoration: none !important;
}

.form-control,
.form-select {
  min-height: 2.344vw !important;
}

.Autocomplete-field ul {
  width: 100%;
  padding: 0 !important;
}

.modalPopupClass .btn-close {
  padding: 0.5vw !important;
  margin: 0 !important;
}

.p-autocomplete {
  display: inline-block;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0 0.1vw 0.1vw !important;
  border: 0 !important;
  margin-bottom: 0 !important;
  box-shadow: none !important;
}

.p-autocomplete-input-token {
  padding: 0.05vw 0 !important;
}

::-webkit-input-placeholder,
.multiSelectDropdown__placeholder {
  color: #21252978 !important;
  font-family: var(--font-roobert-regular);
}

.success {
  width: 0.7vw;
  height: 0.7vw;
  background-color: green !important;
  border-radius: 50%;
}

.brown {
  width: 0.7vw;
  height: 0.7vw;
  background-color: brown !important;
  border-radius: 50%;
}

.danger {
  width: 0.7vw;
  height: 0.7vw;
  background-color: red !important;
  border-radius: 50%;
}

.primary {
  width: 0.7vw;
  height: 0.7vw;
  background-color: rgb(46, 7, 185) !important;
  border-radius: 50%;
}

.cyan {
  width: 0.7vw;
  height: 0.7vw;
  background-color: rgb(86, 210, 248) !important;
  border-radius: 50%;
}

.bg-info {
  width: 0.7vw;
  height: 0.7vw;
  border-radius: 50%;

}

.secondary {
  width: 0.7vw;
  height: 0.7vw;
  background-color: yellow !important;
  border-radius: 50%;
}

.warning {
  width: 0.7vw;
  height: 0.7vw;
  background-color: #8fbc8f !important;
  border-radius: 50%;
}

.orange {
  width: 0.8vw;
  height: 0.8vw;
  background-color: orange !important;
  border-radius: 50%;
}

.dynamicPopupClass .d-grid>div {
  width: 100%;
}

.dynamicPopupClass .d-grid {
  grid-template-columns: repeat(2, 1fr);
  gap: 0vw 1vw;
}

.tableSearchFields .height-20,
.tableSearchFields label {
  display: none;
}

.showLessIcon .table-action-btn {
  background-color: var(--budget-bg) !important;
  color: var(--color-dark-purple) !important;
}

.showLessIcon .table-action-btn:hover {
  background-color: var(--color-dark-purple) !important;
  color: var(--color-white) !important;
}

.textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.form-select.paginationSelect {
  padding: 0.3vw !important;
  min-height: 1.5vw !important;
}

.sortIcon svg {
  width: 0.8vw !important;
  height: 0.8vw !important;
  color: var(--primaryBrandColor);
}

td[data-label="Score"] {
  padding: 8px;
}

.color-dark-pink {
  color: var(--primaryBrandColor);
  font-weight: bold;
  margin-right: 0.8vw;
}

.progressContainer .progress {
  background-color: var(--sideBarBgColor);
  border-radius: 0.5vw;
  height: 0.5vw;
  overflow: hidden;
  width: 100%;
}

.progressContainer .progress-bar {
  background-color: var(--primaryBrandColor);
  height: 100%;
  transition: width 0.3s ease-in-out;
  border-radius: 0.5vw;
}

.fileUploadWrapper {
  cursor: pointer;
  width: fit-content;
}

.candidate-cv-upload,
.matchingCandidateBtn {
  margin-left: 6vw !important;
  margin-top: .5vw !important;
}

.disabled {
  opacity: 0.9;
  pointer-events: none;
}

@media screen and (min-width: 3000px) {
  .row>* {
    padding-right: 0.6vw !important;
    padding-left: 0.6vw !important;
  }
}

.spinnerStyle {
  width: 1vw !important;
  height: 1vw !important;
}

.addressPopup .modal-dialog {
  min-width: 60vw !important;
  max-width: 60vw !important;
}

.addressPopup .modal-footer {
  display: none;
}

.pac-container.pac-logo {
  z-index: 1500 !important;
}

.spinner-border-sm {
  width: 1vw !important;
  height: 1vw !important;
}

.manageTempAgencyHeightNew,
.manageApplicantsTableHeight {
  height: calc(100vh - 14.25vw) !important;
  overflow: auto;
}

.nameHoverClass .hoverText.textEllipsis {
  display: inline-block;
}

.nameHoverClass .hoverText.textEllipsis:hover,
.nameHoverclass:hover,
.nameHoverClass .hoverTextName:hover {
  color: var(--primaryBrandColor);
  text-decoration: underline;
  cursor: pointer !important;
}

.manageInfoIcon .infoHelpText {
  display: none;
}

.manageInfoIcon:hover .infoHelpText {
  display: block;
  position: absolute;
  left: -18.2vw;
  width: 18vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
  background-color: var(--color-dark-purple);
  color: var(--color-white);
  padding: 0.5vw;
  border-radius: 0.5vw;
  font-size: var(--size-16px);
}

.manageInfoIcon:hover .infoHelpText::after {
  content: "";
  position: absolute;
  right: -0.5vw;
  top: 50%;
  width: 0;
  height: 0;
  border-left: 0.817vw solid var(--color-dark-purple);
  border-top: 0.56vw solid transparent;
  border-bottom: 0.56vw solid transparent;
  transform: translateY(-50%);
}

.createNavTabs .btn.active,
.createNavTabs .btn:hover {
  color: var(--primaryBrandColor);
  text-decoration: underline;
  text-decoration-thickness: 0.104vw;
  text-underline-offset: 0.208vw;
}

.createNavTabs .btn {
  font-size: var(--size-18px) !important;
  padding: 0 !important;
}

.profilePictureDesign {
  width: 10vw;
  height: 10vw !important;
  object-fit: cover !important;
  border-radius: 50% !important;
}

.signatureImage {
  width: 10vw;
  height: 10vw !important;
  object-fit: contain;
}

.signature-canvas {
  width: 100% !important;
  height: 15vw !important;
}

.profilePictureWrapper .fileLabel {
  display: none !important;
}

table td.timetable-error-border input,
.error-border {
  border: 0.15vw solid var(--color-red) !important;
}

.competenceGrid {
  grid-template-columns: repeat(6, 1fr);
  gap: 0 1vw;
}

.hoverLink:hover {
  text-decoration: none !important;
}