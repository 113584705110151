.manage-companies th:nth-child(1) {
  width: 11%;
}

.manage-companies th:nth-child(2) {
  width: 9%;
}

.manage-companies th:nth-child(3),
.manage-companies th:nth-child(4),
.manage-companies th:nth-child(5) {
  width: 7%;
}

.manage-companies th:nth-child(6) {
  width: 14%;
}

.manage-companies th:nth-child(8) {
  width: 7vw !important;
  min-width: 7vw !important;
  max-width: 7vw !important;
}

.manage-companies th:nth-child(7) {
  width: 11%;
}

.manage-companies th:nth-child(9) {
  width: 8.5%;
}

.manage-companies th:nth-child(10) {
  width: 5%;
}

.companyVatVerify {
  position: absolute;
  top: 1.25vw;
  right: 0vw;
  padding-left: 1.5vw !important;
  padding-right: 1.5vw !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  height: 2.25vw !important;
}

.locationBg {
  padding: 1vw;
  border-radius: 1vw !important;
  margin-bottom: 1vw !important;
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
}

.createContactPerson .locationBg {
  box-shadow: none !important;
}

.fieldPrefix {
  top: 1.28vw;
  width: 1.8vw;
  height: 2.29vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.052vw solid var(--header-bg-table-color);
  background-color: var(--header-bg-table-color);
  border-top-left-radius: 0.4vw;
  border-bottom-left-radius: 0.4vw;
  font-size: var(--size-16px);
  left: 0.04vw;
}

.generalTabVAT input {
  padding-left: 2vw !important;
  padding-right: 5.5vw !important;
}

.createCompanyContactsTable th {
  width: 20%;
}

.manage-companies .FilterMolecule:nth-child(9) .multiSelectDropdown__placeholder,
.manage-companies .FilterMolecule:nth-child(10) .multiSelectDropdown__placeholder {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.manage-companies.FilterMolecule:nth-child(9) .multiSelectDropdown__placeholder {
  width: 5vw;
}

.manage-companies .FilterMolecule:nth-child(10) .multiSelectDropdown__placeholder {
  width: 2vw;
}

.items-per-page {
  padding-left: 0.25vw !important;
}

.companyCornerFollowUps .header-sticky,
.companyCornerFollowUps .closeIcon  {
  display: none;
}

.companyCornerFollowUps .scrollBarDesign {
  padding: 1.25vw !important;
}

.companyCornerFollowUps .tab-subtitle {
  padding-bottom: 0 !important;
}

.followUpCard {
  padding: 1vw !important;
}