.timesheetTable th {
  width: 8%;
  min-width: 8%;
  max-width: 8%;
  height: 1.8vw !important;
  font-size: 0.75vw !important;
}

.timesheetContentWrapper table thead {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  z-index: 99;
}

.timesheetContentWrapper .sidebar_bg th,
.timesheetTable thead th {
  outline: 0.05vw solid var(--color-white);
  text-align: center;
  font-family: var(--font-roobert-bold);
  border: 0.05vw solid var(--color-white) !important;
  font-size: 0.75vw !important;
}

#invoice_milage,
#holiday,
#purchase_invoice {
  margin-right: 0 !important;
}

.customDatePicker .rmdp-container {
  width: 100%;
}

.timeTimingCol input:focus {
  border: 0.052vw solid var(--primaryBrandColor) !important;
}

.timesheetContentWrapper {
  font-size: 0.82vw !important;
}

.timeSheetProjectAndEmployee tbody td {
  padding: 0vw !important;
}

.timeSheetProjectAndEmployee tbody td span {
  padding: 0.25vw !important;
}

.timeSheetProjectAndEmployee tbody td:hover span {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
  cursor: pointer !important;
}

.timeSheetProjectAndEmployee tbody td:hover * {
  cursor: pointer !important;
}

.timeSheetGrid {
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5vw;
}

.timeSheetProjectAndEmployee table,
.projectParameterTable,
.projectParameterTableTwo {
  border-left: 0.15vw solid var(--sideBarBgColor);
  border-right: 0.15vw solid var(--sideBarBgColor);
  border-bottom: 0.15vw solid var(--sideBarBgColor);
}

.timeSheetLeftSection {
  padding-right: 0.5vw !important;
  font-size: 0.75vw !important;
}

.weekDaysCol,
.timeSheetLeftSection thead th,
.projectParameterTable thead th,
.projectParameterTableTwo th {
  padding: 0.25vw !important;
}

.timeSheetLeftSection thead th,
.timesheetTable thead th,
.projectParameterTable th,
.projectParameterTableTwoTitle th {
  height: 1.8vw;
}

.timesheetTable tbody td {
  padding: 0.25vw;
  background-color: #f6f6f8;
  border: 0.05vw solid var(--color-white) !important;
  font-size: 0.75vw !important;
}

.timeSheetRightSection {
  padding-left: 0.5vw !important;
  font-size: 0.75vw !important;
}

.projectParameterTable {
  margin-bottom: 0.5vw !important;
}

.projectParameterTable td {
  padding: 0.25vw;
  text-align: left;
}

.projectParameterTableTwo td {
  padding: 0.15vw;
  text-align: left;
}

/* .projectParameterTable tr:nth-child(even) td {
  padding-left: 0.8vw !important;
  padding-top: 0 !important;
} */

.performanceTableData {
  padding: 0 !important;
}

.calculatorPosition {
  right: 0.25vw;
  top: 0.25vw;
}

.timesheetTable thead th {
  position: relative;
  top: 0.015vw;
  background-color: rgb(246, 246, 248);
}

.timesheetWeekNumber * {
  font-size: 1vw !important;
  white-space: nowrap;
}

.timesheetWeekNumber {
  margin-right: 0.5vw;
}

.timesheetTable tbody tr:nth-child(5) td {
  border-top: 0.15vw solid #00a5cea1 !important;
}

.convertToHoursTimeFieldTwo .height-20 {
  display: none;
}

.convertToHoursTimeFieldTwo input {
  height: 2vw !important;
  min-height: 2vw !important;
}

.timesheetGridTemplate {
  grid-template-columns: repeat(2, 1fr);
  gap: 0 0.2vw;
}

.timesheetGridTemplate>div:nth-child(odd) {
  text-align: left;
}

.timesheetGridTemplate>div:nth-child(even) {
  text-align: right;
}

.timesheetGridTemplate * {
  font-size: 0.65vw !important;
}

.timesheethovermain {
  font-size: 0.7vw;
}

.moreInfoSection {
  height: 15%;
  overflow: auto;
}

.projectParametersSection {
  height: 65%;
  overflow: auto;
  margin-bottom: 0.5vw;
}

.regimeHeight {
  height: 1vw !important;
}
