.manage-email-template th:nth-child(1)
{
  width: 10%;
}
.manage-email-template th:nth-child(2)
{
  width: 40%;
}
.manage-email-template th:nth-child(3)
{
  width: 35%;
}
.manage-email-template th:nth-child(4)
{
  width: 15%;
}
/* Edit email template */

/* Language dropdown */
.dropdown-toggle
{
  border: 1px solid var(--color-dark-purple) !important;
}
.dropdown-menu.show
{
  min-width: 38px;
  padding: 2px;
}
.dropdown-item.active, .dropdown-item:active
{
  background-color: transparent !important;
  color: var(--color-dark-skyblue) !important;
}
.body-tokens > div,
.signature-tokens > div
{
  height: 100%;
}
.body-tokens textarea.form-control,
.signature-tokens textarea.form-control
{
  min-height: calc(100% - 64px);
  color: var(--color-dark-purple) !important;
  line-height: 2;
}
.view-height
{
  min-height: calc(100vh - 350px);
}
.create-email-template .ck-content
{
  min-height: 5rem !important;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable)
{
  border: 1px solid var(--color-dark-purple);
  box-shadow: none;

}