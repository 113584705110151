.file-upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  background-color: var(--color-white);
  cursor: pointer;
  height: 2.344vw;
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
  border-radius: 0.5vw;
  transition: all 0.5s ease;
}

.file-upload-wrapper:hover {
  background-color: var(--color-dark-skyblue);
}

.upload-btn {
  padding: 0px;
  border-radius: 8px;
  font-size: var(--size-16px);
  color: var(--color-dark-skyblue) !important;
  font-family: var(--font-vfutura-medium) !important;
  cursor: pointer;
  height: 2.344vw;
  padding-left: 1.5vw !important;
  padding-right: 1.5vw !important;
}

.upload-btn svg {
  width: 1.2vw !important;
  height: 1.2vw !important;
}

.uploadFileText {
  font-size: var(--size-16px) !important;
  font-family: var( --font-roobert-regular);
  line-height: 0vw !important;
}

.file-upload-wrapper:hover .upload-btn {
  color: var(--color-white) !important;
  /* justify-content: start !important; */
}

.file-upload-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 2.344vw;
  width: 100%;
  cursor: pointer;
}

.h-38 {
  height: 38px;
}

.file-name {
  width: 240px;
}

.uploadedFileName:hover {
  color: var(--primaryBrandColor);
  text-decoration: underline !important;
}

.profileCardImage {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.candidateProfileCVUpload .file-upload-wrapper {
  display: inline-flex !important;
}

.candidateProfileCVUpload .file-upload-wrapper .upload-btn {
  width: fit-content !important;
}
