/* / Header / */
.page-header {
  background-color: var(--color-white);
  height: 3.8vw;
  top: 0;
  z-index: 999;
}

.this-week-section .dropdown-toggle {
  border: 0.052vw solid var(--sideBarBgColor) !important;
}

.this-week-link {
  background-color: var(--sideBarBgColor) !important;
  height: 2.344vw;
  color: var(--primaryBrandColor) !important;
  font-size: var(--size-18px) !important;
}

.this-week-section {
  margin-right: 3.5vw;
}

.dashboardCurrentCalendarIcon {
  background-color: var(--sideBarBgColor);
  border: 0.052vw solid var(--sideBarBgColor);
  border-radius: 0.417vw;
  box-shadow: none;
  color: var(--primaryBrandColor);
  height: 2.344vw;
  position: absolute;
  top: 0;
  width: 2.344vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardCurrentCalendarIcon:hover {
  background-color: var(--primaryBrandColor);
  color: var(--color-white);
}

.dashboardCurrentCalendarIcon span {
  font-size: var(--size-18px) !important;
}

.monthPrevIcon {
  left: -2.604vw;
}

.monthNextIcon {
  right: -2.604vw;
}


.header-sticky {
  background-color: var(--color-white);
  height: 2.604vw;
  top: 3.8vw;
  z-index: 998;
}

.page-title,
.subform-title .page-title {
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-bold);
  font-size: var(--size-20px);
}

.logOutIcon img {
  width: 1.5vw;
  height: 1.5vw;
}

.profileLink {
  padding-left: 0.5vw !important;
  padding-right: 0.5vw !important;
}

.profileLink:hover {
  text-decoration: none !important;
}

.headerLanguage {
  min-width: 7vw;
  height: 2.34vw !important;
  max-height: 2.34vw !important;
  min-height: 2.34vw !important;
  font-size: 0.9vw !important;
  padding: 0.2vw 0.3vw !important;
  border-radius: 0.4vw !important;
}

.headerBtn {
  font-size: 0.7vw !important;
  white-space: nowrap;
  /* max-width: 6vw;
  text-overflow: ellipsis;
  overflow: hidden; */
  font-family: var( --font-roobert-medium) !important;
  color: var(--primaryBrandColor) !important;

}

.headerViewLink {
  margin-right: 0.5vw;
}

.headerBtn:hover,
.headerPageLink .present  {
  color: var(--color-dark-purple) !important;
  padding: 0 !important;
  line-height: 1vw !important;
}

.headerPageLink .present {
  padding-right: 0.25vw !important;
}
.headerBtn:hover{
  color: var(--primaryBrandColor) !important;
  text-decoration: none !important;
}

.headerPageLink {
  width: 75%;
  height: 2.344vw !important;
  max-height: 2.344vw !important;
  min-height: 2.344vw !important;
  font-size: var(--size-16px) !important;
  border-radius: 0.5vw !important;
  padding-left: 0.5vw !important;
  padding-right: 0.5vw !important;
}

.arrowHeightHeader {
  height: 1.2vw !important;
  line-height: 1vw !important;
}
