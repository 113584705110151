.default-coefficient-table .TableHeader:nth-child(2) th {
    width: 10%;
  }
  
  .default-coefficient-table tbody td {
    height: 3rem;
    padding: 0rem;
  }
  
  .default-coefficient-table tbody td .col-md-12 {
    height: 100%;
  }
  
  .default-coefficient-table tbody th:nth-child(1) {
    width: 15%;
  }
  
  .default-coefficient-table tbody th:nth-child(2) {
    width: 20%;
  }
  
  .default-coefficient-table tbody th {
    color: var(--color-dark-purple) !important;
    font-family: var(--font-vfutura-light);
    /* font-weight: normal; */
  }
  
  .default-coefficient-table tbody td .col-md-12>input:focus-visible {
    outline: 1px solid var(--color-dark-purple) !important;
    text-align: center;
  }
  
  .coefficient {
    text-align: center;
  }
  .default-coefficient-table .TableHeader th
  {
    border: 1px solid var(--color-dark-purple);
  }
  .error-coefficients {
    font-weight: bold;
  }