/* / For the entire scroll bar / */
.leftMenuWrapper::-webkit-scrollbar,
.scrollBar .multiSelectDropdown__control::-webkit-scrollbar,
.scrollBarDesign::-webkit-scrollbar {
  width: 0.3vw; /* Width of the scrollbar */
  height: 0.3vw;
}

.leftMenuWrapper::-webkit-scrollbar-track,
.scrollBar .multiSelectDropdown__control::-webkit-scrollbar-track,
.scrollBarDesign::-webkit-scrollbar-track {
  background: rgb(226, 226, 226);
  border-radius: 0.5vw;
}

.leftMenuWrapper::-webkit-scrollbar-thumb,
.scrollBar .multiSelectDropdown__control::-webkit-scrollbar-thumb,
.scrollBarDesign::-webkit-scrollbar-thumb {
  background: var(--primaryBrandColor);
  border-radius: 0.5vw;
}

.main-menu {
  background: var(--sideBarBgColor);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  position: fixed !important;
  top: 0;
  transition: width 0.2s linear;
  z-index: 1000;
  overflow: auto;
  padding-bottom: 2vw;
  padding-left: 1vw;
  margin-right: 1vw;
  transition: width 0.5s ease;
  width: 4.9vw;
}

.leftMenuWrapper {
  height: calc(100vh - 9vw) !important;
  overflow: auto;
  padding-right: 1vw;
}

.sidebarLogo {
  height: 3.5vw;
}

.sidebar-menu svg {
  width: 1.4vw;
  height: 1.4vw;
  color: var(--action-icon-color);
  /* / stroke: var(--action-icon-color); / */
}

.sidebar-menu.active svg *,
.sidebar-menu:hover svg * {
  /* / stroke: var(--color-white); / */
  color: var(--color-white);
}

.sidebar-menu {
  margin-bottom: 0.5vw !important;
  transition: all 0.5s ease-in-out;
}

.sidebarLogo a {
  padding-left: 0.3vw !important;
}

.sidebar-logo {
  width: 2vw;
}

.sidebar-menu.active,
.sidebar-menu:hover {
  background-color: var(--primaryBrandColor);
  border-radius: 0.7vw;
  box-shadow: 0 0.104vw 0.417vw 0 #00a5cecc;
}

.sidebar-menu a {
  text-decoration: none !important;
  width: 100%;
  display: block;
  padding: 0.6vw;
}
.main-menu.expanded .sidebar-menu.active .nav-text,
.main-menu.expanded .sidebar-menu:hover .nav-text {
  color: var(--color-white) !important;
}

.main-menu.expanded .nav-text {
  min-height: 1.3vw;
  color: var(--action-icon-color);
  font-size: var(--size-16px);
}

.sidebar-icon {
  background-color: var(--color-white);
  border: 0.052vw solid var(--primaryBrandColor);
  box-shadow: 0 0.104vw 0.417vw 0 #00a5cecc;
  color: var(--primaryBrandColor) !important;
  height: 1.563vw;
  width: 1.563vw;
  transition: all 0.5s ease;
  border-radius: 0.4vw;
}

.sidebar-icon:hover {
  background-color: var(--primaryBrandColor);
  color: var(--color-white) !important;
}

.main-menu.expanded {
  width: 13vw;
}

.expanded-sidebar-logo {
  width: 9vw;
  padding-left: 0.3vw;
}

.sidebarIcon {
  bottom: 0.75vw;
  left: 1.5vw;
}

.main-menu.expanded .sidebarIcon {
  right: 0;
}

/* / Right sidebar / */
.right-sidebar {
  position: fixed;
  right: 0.677vw;
  bottom: 0;
  width: 18.229vw;
  height: calc(100vh - 6.354vw);
  background-color: var(--color-white);
  box-shadow: rgba(229, 0, 126, 0.516) 0vw 0.052vw 0vw,
    rgba(229, 0, 126, 0.316) 0vw 0vw 0.104vw;
  z-index: 1000;
  overflow-y: auto;
  padding: 1.042vw;
  transition: width 0.3s ease;
  -webkit-transform: translateZ(0) scale(1, 1);
  border-radius: 0vw !important;
}

.close-btn {
  position: absolute;
  top: 0.521vw;
  right: 0.521vw;
  background: none;
  border: none;
  font-size: 0.833vw;
  cursor: pointer;
}

.followUpTimings {
  font-size: var(--size-16px);
  font-family: var(--font-roobert-semibold);
}

.candidateCornerPage .right-sidebar {
  height: calc(100vh - 15.625vw);
}

.rightSidebar {
  margin-right: 0;
  transition: margin-right 0.3s ease;
}

.main-menu.expanded + .page-width {
  margin-left: 13vw !important;
}

@-moz-document url-prefix() {
  .leftMenuWrapper {
      height: calc(100vh - 9vw) !important;
      overflow: auto;
      padding-right: 1.2vw;
  }
}
