.manageApplicantsWrapper th:nth-child(1) {
  width: 7vw !important;
  min-width: 7vw !important;
  max-width: 7vw !important;
}

.manageApplicantsWrapper th:nth-child(2),
.manageApplicantsWrapper th:nth-child(4) {
  width: 11%;
}

.manageApplicantsWrapper th:nth-child(3) {
  width: 15%;
}

.manageApplicantsWrapper th:nth-child(5),
.manageApplicantsWrapper th:nth-child(6) {
  width: 10%;
}

.manageApplicantsWrapper th:nth-child(7) {
  width: 12%;
}

.manageApplicantsWrapper th:nth-child(8) {
  width: 8%;
}

.manageApplicantsWrapper th:nth-child(9) {
  width: 7%;
}

.manageApplicantsWrapper td:nth-child(1) {
  max-width: 7vw;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.applicationAddressStyle,
.grid-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
}

.backPadding {
  padding-top: 0.85vw !important;
  padding-bottom: 0.85vw !important;
}