.manageCooperationAgreement th:nth-child(1) {
    width: 7vw !important;
    min-width: 7vw !important;
    max-width: 7vw !important;
}

.manageCooperationAgreement th:nth-child(2){
    width: 20%;
}
.manageCooperationAgreement th:nth-child(3) {
    width: 15%;
}

.manageCooperationAgreement th:nth-child(4) {
    width: 25%;
} 

.manageCooperationAgreement th:nth-child(5),
.manageCooperationAgreement th:nth-child(6)  {
    width: 8.5%;
}