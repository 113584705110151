.manage-holiday-codes th:nth-child(1),
.manage-holiday-codes th:nth-child(3),
.manage-holiday-codes th:nth-child(4),
.manage-holiday-codes th:nth-child(5) {
  width: 17%;
}

.manage-holiday-codes th:nth-child(2),
.manage-holiday-codes th:nth-child(6) {
  width: 11%;
}

.publicHolidayYear .rmdp-day, 
.publicHolidayYear .rmdp-week-day {
  height: 2.2vw !important;
}

.publicHolidayYear .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--color-dark-skyblue);
}

.publicHolidayYear .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover {
  background-color: var(--color-dark-skyblue) !important;
}


/* Public holiday */
.manage-publicholiday-codes th {
  width: calc(100% / 5);
}

.managePublicHoliday {
  height: calc(100vh - 18vw);
  overflow: auto;
}

/* Holiday per pc */
.manageHolidayPerPC  th:first-child {
  width: 80%;
}

.link-holiday-per-pc {
  max-height: calc(100vh - 16vw);
  overflow: auto;
}

.manageHolidayPerPCHeight {
  height: calc(100vh - 16vw);
  overflow: auto;
}