.createInvoiceTable {
    border-radius: 1vw !important;
    padding: 1vw !important;
    margin-top: 0.25vw !important;
}
.invoiceTitle {
    font-size: 1vw !important;
}
.createInvoiceHeader .calender-icon {
    right: 0.05vw !important;
}
.createInvoiceHeader {
    margin-bottom: 0.5vw !important;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5vw 1vw !important;
}

.attachmentsTable th:first-child {
    width: 7%;
}

.attachmentsTable th:nth-child(2) {
    width: 8%;
}

.attachmentsTable th:nth-child(3),
.attachmentsTable th:nth-child(4),
.attachmentsTable th:nth-child(5),
.attachmentsTable th:nth-child(6) {
    width: 13%;
} 

.attachmentsTable th:nth-child(7),
.attachmentsTable th:nth-child(8),
.attachmentsTable th:nth-child(9),
.attachmentsTable th:nth-child(10) {
    width: 7%;
}

.attachmentsTable {
    height: calc(100vh - 23vw);
    overflow: auto;
}

.invoiceTotals th {
  width: 50%;
}

.invoiceDetailsLines th:first-child {
    width: 28%;
}

.invoiceDetailsLines th:nth-child(2),
.invoiceDetailsLines th:nth-child(3),
.invoiceDetailsLines th:nth-child(4),
.invoiceDetailsLines th:nth-child(5),
.invoiceDetailsLines th:nth-child(6),
.invoiceDetailsLines th:nth-child(7)     {
    width: 10%;
}