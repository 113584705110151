.field-label {
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-regular);
  font-size: var(--size-16px);
}

.field-shadow {
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
}

.form-control {
  border: 0.052vw solid var(--color-white) !important;
  border-radius: 0.4vw !important;
  font-size: 0.8vw !important;
  min-height: 2.344vw;
  padding: 0.5vw !important;
  transition: border 0.3s ease;
}

.convertToHoursTime input[type="text"] {
  border: 0.052vw solid var(--color-white) !important;
  border-radius: 0.4vw !important;
  font-size: 0.8vw !important;
  height: 2vw;
  padding: 0.5vw !important;
  transition: border 0.3s ease;
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
}
.convertToHoursTime input[type="text"]:focus {
  outline: none !important;
  border: 0.052vw solid var(--primaryBrandColor) !important;
}

.form-control:focus {
  border: 0.052vw solid var(--primaryBrandColor) !important;
}

/* / Link color / */
.link-color {
  color: var(--primaryBrandColor);
  font-size: var(--size-18px);
}

/* / Phone input / */
.PhoneInputInput {
  border: 0 !important;
}

.PhoneInput--focus .PhoneInputInput {
  outline: 0 !important;
  border: 0 !important;
}

/* / Button / */
.form-button,
.fleetAndMaterialBtnActive {
  background-color: var(--primaryBrandColor) !important;
  border: 0.052vw solid var(--primaryBrandColor) !important;
  border-radius: 0.4vw;
  color: var(--color-white);
  font-size: var(--size-16px) !important;
  min-height: 2.344vw;
  padding: 0.5vw !important;
  transition: all 0.5s ease;
}

.form-button:hover {
  background-color: var(--sideBarBgColor) !important;
  color: var(--primaryBrandColor) !important;
}

.close-button,
.modal-cancel-btn,
.delete-btn {
  background-color: var(--color-white) !important;
  border: 0.052vw solid var(--color-dark-purple) !important;
  border-radius: 0.4vw;
  color: var(--color-dark-purple) !important;
  font-size: var(--size-16px) !important;
  min-height: 2.344vw;
  padding: 0.5vw !important;
  transition: background-color 0.5s ease, color 0.5s ease;
  line-height: 0vw !important;
}

.close-button:hover,
.modal-cancel-btn:hover,
.delete-btn:hover {
  background-color: var(--color-dark-purple) !important;
  color: var(--color-white) !important;
}

/* Yes Button */
.modal-confirm-btn,
.edit-btn {
  border: 1px solid var(--color-dark-skyblue) !important;
  background-color: var(--color-dark-skyblue) !important;
  color: var(--color-white) !important;
  height: 38px;
}

.modal-confirm-btn:hover,
.edit-btn:hover {
  color: var(--color-dark-skyblue) !important;
  background-color: var(--color-white) !important;
  transition: all 0.3s ease-in-out;
}


/* / Checkbox / */

.form-check-input,
.p-checkbox-box {
  border: 0.052vw solid var(--primaryBrandColor) !important;
  height: 1vw !important;
  width: 1vw !important;
}

.form-check-input:checked[type=checkbox],
.p-checkbox.p-highlight .p-checkbox-box {
  background-color: var(--primaryBrandColor) !important;
  border: 0.053 solid var(--primaryBrandColor) !important;
  accent-color: var(--primaryBrandColor);
}

.form-check-input:checked {
  background-color: var(--color-dark-skyblue) !important;
  border-color: var(--color-dark-skyblue) !important;
}

.search-bar,
.search-bar-error {
  position: sticky;
  top: 3.8vw;
  z-index: 999;
  background-color: var(--color-white);
  padding-bottom: 0.25vw;
}


/* / action / */

.table-action-btn {
  width: 1.8vw;
  height: 1.8vw;
  background-color: var(--sideBarBgColor) !important;
  color: var(--primaryBrandColor) !important;
  border-radius: 50% !important;
  transition: all 0.5s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.table-action-btn:hover {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
}

.table-action-btn svg {
  width: 1vw !important;
  height: 1vw !important;
}

.back-btn {
  color: var(--color-dark-purple) !important;
  font-size: 0.9vw !important;
}

/* / Multiselect field / */

.multiSelectDropdown__multi-value__label {
  font-size: 0.8vw !important;
  padding: 0.1vw 0.5vw !important;
  white-space: break-spaces !important;
}

.multiSelectDropdown__multi-value {
  margin: 0.1vw 0.25vw 0.1vw 0.1vw !important;
}

.multiSelectDropdown__control--is-focused {
  border-color: var(--color-dark-pink) !important;
  box-shadow: 0 0 0 0.052vw var(--shadowColor) !important;
}

.multiSelectDropdown__dropdown-indicator,
.form-select {
  background-image: url('../images/dropdown.svg') !important;
  background-size: 0.677vw !important;
  background-repeat: no-repeat;
  background-position: 0vw;
  cursor: pointer;
  padding: 0.5vw !important;
}

.multiSelectDropdown__dropdown-indicator svg {
  display: none;
}

.multiSelectDropdown__clear-indicator svg {
  fill: var(--color-dark-pink);
  width: 1.2vw !important;
  height: 1.2vw !important;
  cursor: pointer;
}

.multiSelectDropdown__control>div.multiSelectDropdown__indicators {
  min-width: 1.25vw;
}

.multiSelectDropdown__value-container {
  padding: 0 0.35vw !important;
}

.multiSelectDropdown__input-container,
.multiSelectDropdown__placeholder {
  margin-left: 0px !important;
}

.searchBtnWrapper {
  margin-top: 1.4vw !important;
}

.addBtnMargin {
  margin-top: 1.2vw !important;
}

.plusIconSPace {
  margin-top: 1.75vw !important;
}

.checkBoxLabel {
  cursor: pointer !important;
}

.search-bar .height-20,
.hideHeight .height-20 {
  display: none;
}

.inputField .inputFieldSuffix {
  right: 0;
  z-index: 5;
  height: 2.2vw;
  top: 0.05vw;
  background-color: var(--sideBarBgColor);
  border: 0 !important;
}

/* Date picker */

.datePicker .rmdp-container {
  width: 100%;
}

.css-16xfy0z-control,
.disabled input[type='tel'],
.form-control:disabled,
.form-select:disabled,
.time-section .hourWrapper select:disabled,
.time-section .minuteWrapper select:disabled,
input:disabled,
.form-control.disabled,
.multiSelectDropdown__control--is-disabled,
.form-control:has(.PhoneInput--disabled) {
  background-color: #e8f8fb75 !important;
  border: 0.052vw solid #e8f8fb75 !important;
}

.hideLabel label {
  display: none;
}

.searchSpacing {
  margin-bottom: 1vw;
}

/* Fleet and material */


.manageTranslation {
  height: calc(100vh - 14vw);
  overflow: auto;
}

.addDateMargin {
  margin-top: 1.4vw !important;
}

.units {
  background-color: var(--sideBarBgColor);
  height: 2.25vw;
  right: 0.05vw;
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  z-index: 5;
  top: 0.045vw;
  min-width: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25vw !important;
}

#date-time-edit>div>* {
  padding: 0 !important;
}

.ck-editor {
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
  border-radius: 0.6vw;
  border: 0 !important;
}

.ck.ck-editor__main>.ck-editor__editable {
  border-bottom-left-radius: 0.6vw !important;
  border-bottom-right-radius: 0.6vw !important;
  min-height: 20vw !important;
}

.ck-toolbar {
  border-top-left-radius: 0.6vw !important;
  border-top-right-radius: 0.6vw !important;
}

.ck.ck-editor__main p {
  font-size: var(--size-16px) !important;
  margin-bottom: 0.7vw !important;
  line-height: 1.5vw;
}

.ck.ck-toolbar>.ck-toolbar__items {
  font-size: 0.8vw !important;
}

.ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content {
  border-bottom: 0.052vw solid rgba(0, 165, 206, 0.7) !important;
  border-top: 0.052vw solid var(--color-white) !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 0.002vw solid var(--primaryBrandColor) !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border: 0.052vw solid var(--color-white) !important;
}

.ck.ck-icon {
  width: 1vw !important;
  height: 1vw !important;
}

.emailTemplateLanguage .dropdown-toggle {
  border: 0.052vw solid var(--color-dark-purple) !important;
  font-size: var(--size-16px);
}

.dropdown-item {
  font-size: var(--size-18px);
}

.wageElementsData .height-20 {
  display: none;
}

.wageElementsData .input-group-text {
  background-color: var(--sideBarBgColor);
  height: 2.25vw;
  right: 0.05vw;
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  z-index: 5;
  top: 0.05vw;
  padding: 0.8vw !important;
  font-size: var(--size-16px);
  position: absolute;
}

/* employees */
.mealVoucherEmployees .d-inline-block,
.employeeStudiesCompleted .d-inline-block,
.workExperienceCol .d-inline-block,
.referenceCol .d-inline-block {
  margin-right: 1vw !important;
}

/* Range bar  */

.form-range {
  appearance: auto !important;
}

.sliderPink,
.sliderPink:hover {
  accent-color: var(--primaryBrandColor);
  background-color: var(--primaryBrandColor);
}

.range-wrap .bubble {
  display: none;
}

.range-wrap:hover .bubble {
  display: block;
}

.form-range::-webkit-slider-thumb {
  background-color: var(--primaryBrandColor) !important;
  box-shadow: none;
  width: 1vw !important;
  height: 1vw !important;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: none !important;
}

.compentences .form-range {
  height: 0.5vw !important;
}

.compentences .form-range::-webkit-slider-runnable-track {
  background-color: #999c9f00;
}

::-webkit-inner-spin-button {
  display: none;
}

.range-wrap {
  position: relative;
}

.bubble {
  background: var(--primaryBrandColor);
  color: var(--color-white);
  padding: 0.5vw 1vw;
  position: absolute;
  border-radius: 0.3vw;
  left: 50%;
  transform: translateX(-50%);
  top: 1vw;
}

.bubble::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 2px;
  background: var(--primaryBrandColor);
  top: -1px;
  left: 50%;
}

.compentences .form-range {
  height: 0.5vw !important;
}

.leaseRentalCar {
  padding-top: 0.5vw;
}

.form-check-input[type=checkbox]:disabled {
  border: 0.052vw solid var(--primaryBrandColor) !important;
}

.multiSelectDropdown--is-disabled {
  background-image: none !important;
}

.myProfilePictureWrapper img,
.updatedSignature .border {
  width: 10vw !important;
  height: 10vw !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  box-shadow: rgb(0 165 206 / 70%) 0vw 0.052vw 0.104vw 0vw, rgb(0 165 206 / 95%) 0.09vw 0.032vw 0.256vw 0.152vw !important;
  max-width: 10vw !important;
}


.p-multiselect-items {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.p-multiselect-filter {
  box-shadow: none;
  box-shadow: rgba(0, 165, 206, 0.7) 0vw 0.052vw 0.104vw 0vw,
    rgba(0, 165, 206, 0.3) 0vw 0.052vw 0.156vw 0.052vw !important;
  border-radius: 0.5vw;
  border: 0.052vw solid var(--circlePrimaryColor);
  padding: 0.25vw;
  min-height: 2.344vw;
}

.p-multiselect-filter:hover {
  border: 0.052vw solid var(--color-dark-pink) !important;
}

.p-multiselect-filter-container svg {
  display: none !important;
}

.p-multiselect-label {
  height: 100%;
  max-width: 80%;
  padding: 0.25vw !important;
  display: flex;
  align-items: center;
}

.p-multiselect .p-multiselect-trigger {
  width: 1.5vw !important;
}

.p-multiselect-label-container {
  height: 2.2vw !important;
  max-width: 100%;
}

.p-multiselect-item,
.p-multiselect-filter {
  font-size: var(--size-16px);
  padding: 0.5vw !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  padding: 0.15vw;
  border-radius: 0 !important;
}

.clear-selection-button {
  font-size: var(--size-16px);
}

.p-multiselect-items-wrapper {
  max-height: 10vw !important;
}

.p-multiselect-panel {
  width: 16vw !important;
}

.p-multiselect-item span {
  white-space: break-spaces;
}

.p-checkbox-box svg {
  width: 1vw !important;
  height: 1vw !important;
}

.tableSearchFields .p-multiselect {
  display: flex;
  align-items: center;
}

.tableSearchFields th,
.tableSearchFields td,
.tablePadding th {
  padding: 0.2vw !important;
}

.p-multiselect-item,
.p-multiselect-filter {
  font-size: var(--size-16px);
  padding: 0.5vw !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  padding: 0.15vw;
  border-radius: 0 !important;
}

.clear-selection-button {
  font-size: var(--size-16px);
}

.p-checkbox {
  align-items: center;
  width: 1.4vw !important;
  height: 1.4vw !important;
}

.p-multiselect-trigger svg {
  display: none;
}

.react-datepicker {
  font-size: var(--size-14px) !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  margin: 0.2vw !important;
  width: 1.5vw !important;
  line-height: 1.5vw !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  font-size: 0.8vw !important;
}

.multiSelectDropdown__menu-list {
  max-height: 10vw !important;
}

.form-check-input:disabled[type="radio"] {
  background-color: transparent !important;
}

.hideLabel label,
.hideLabel .height-20,
.hideHeight .height-20 {
  display: none;
}

.agreement-wage-elements>* {
  width: 100%;
}

.megaphoneIcon {
  width: 5vw !important;
}

.megaphoneIcon {
  width: 6vw !important;
}

.textFieldBg {
  font-size: var(--size-16px) !important;
}

.backBtn {
  font-size: var(--size-18px) !important;
}

.multiSelectDropdown__multi-value__remove svg {
  width: 0.8vw !important;
  height: 0.8vw !important;
}

.marginRight1 {
  margin-right: 1vw;
}

.marginLeft1 {
  margin-left: 1vw;
}

.marginTop1 {
  margin-top: 1vw !important;
}

.marginTopPoint5 {
  margin-top: 0.5vw !important;
}

.marginBotttom1 {
  margin-bottom: 1vw !important;
}

.marginBottomPoint5 {
  margin-bottom: 0.5vw !important;
}

.marginBottomPointTwo5 {
  margin-bottom: 0.25vw !important;
}

.marginRightPoint5 {
  margin-right: 0.5vw !important;
}

.marginRightPoint2 {
  margin-right: 0.2vw !important;
}
.padding1 {
  padding: 1vw !important;
}

.paddingTop2 {
  padding-top: 2vw !important;
}

.paddingBottom1 {
  padding-bottom: 1vw !important;
}

.marginLeft1 {
  margin-left: 1vw !important;
}

.merginLeft2 {
  margin-left: 2.5vw !important;
}

.pi-times:before {
  color: var(--primaryBrandColor) !important;
}

.clear-selection-button .p-button-icon {
  font-size: var(--size-16px) !important;
}

.multiSelectDropdown__option {
  font-size: 0.85vw !important;
  padding: 0.5vw !important;
}

.modal-dialog {
  min-width: 40vw !important;
  max-width: 40vw !important;
}

/* .planningMonthHeader th:not(:first-child),
.planningWeekly th:not(:first-child){
  display: none;
} */
.planned-event-box .bottom {
  top: 100%
}



.customDatePicker .input-group-text {
  padding: 0.3vw !important;
}

/* Toastfier */
.Toastify__toast-container {
  width: 18vw !important;
  padding: 1vw !important;
  z-index: 100000 !important;
}

.Toastify__toast-body {
  padding: 0.5vw !important;
}

.Toastify__toast-icon {
  width: 1vw !important;
  height: 1vw !important;
  margin-right: 0.5vw !important;
}

.rmdp-day span {
  font-size: 0.8vw !important;
  padding: 0.3vw 0.25vw !important;
  left: 0.1vw !important;
  border-radius: 0.1vw !important;
  top: 0.1vw !important;
  bottom: 0.1vw !important;
}

.rmdp-header-values span {
  font-size: 0.8vw !important;
}

.rmdp-header .rmdp-arrow {
  width: 0.5vw !important;
  height: 0.5vw !important;
}

.rmdp-arrow-container {
  width: 1.5vw !important;
  height: 1.5vw !important;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
}

.rmdp-arrow-container:hover {
  background-color: var(--color-dark-skyblue) !important;
}

.only.rmdp-month-picker, .only.rmdp-year-picker {
  width: 14vw !important;
  height: 9vw !important;
}

.rmdp-day, .rmdp-week-day {
  height: 1.2vw !important;
}

.rmdp-calendar {
  padding: 0.5vw !important;
}

.modal .btn-close {
  background: var(--color-dark-skyblue) center/1em auto no-repeat !important;
  --bs-btn-close-bg: none !important;
  background-image: url("../images/ModalClose.svg") !important;
  position: absolute;
  left: 50%;
  top: -0.521vw;
  opacity: 1;
  padding: 0.5vw !important;
  border-radius: 0.5vw !important;
  transform: translate(-50%, -0%);
}

.modal-header {
  padding: 1vw 0 !important;
}

.planned-event-box .top {
  bottom: 100%
}


.FilterMolecule .calender-icon svg {
  width: 0.8vw !important;
  height: 0.8vw !important;
}

.FilterMolecule .calender-icon {
  top: 0.5vw !important;
  right: 0.25vw !important;
}

.svg-inline--fa.fa-magnifying-glass {
  height: 1vw !important;
}

[dir=ltr] .ck.ck-button .ck-button__label, [dir=ltr] a.ck.ck-button .ck-button__label {
  font-size: 0.8vw !important;
}

.svg-inline--fa.fa-magnifying-glass {
  height: 0.8vw !important;
  width: 0.8vw !important;
}

.grid-col-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 0 1vw;
}