.manage-extras .price-coeff label,
.manage-extras .price-coeff .height-20 {
  display: none;
}

.manage-extras table thead th:nth-child(1) {
  width: 23%;
}

.manage-extras table thead th:nth-child(2) {
  width: 15%;
}

.manage-extras table thead th:nth-child(3),
.manage-extras table thead th:nth-child(4),
.manage-extras table thead th:nth-child(8) {
  width: 10%;
  text-align: center;
}

.manage-extras table thead th:nth-child(5) {
  width: 9%;
  text-align: center;
}

.manage-extras table thead th:nth-child(6) {
  width: 12%;
}

.manage-extras table thead th:nth-child(7) {
  width: 12%;
}

@media screen and (min-width:1200px) and (max-width:1399px) {

  .manage-extras table thead th:nth-child(2) {
    width: 12%;
  }
}

@media screen and (min-width:1024px) and (max-width:1199px) {

  .manage-extras table thead th:nth-child(2) {
    width: 13%;
  }
}

.manage-extras table .input-group>input,
.manage-extras table .input-group-text {
  padding: 6px;
}

.extraBorder {
  border: 2px solid red !important;
}

.error-coefficients {
  font-weight: bold;
}