.manageBusinessHeight,
.mamagePcHeight,
.manageContractTemplateHeight,
.manageTempAgencyHeight,
.manageCefficientDeviationHeight,
.manageEmployeeTypeHeight {
  height: calc(100vh - 14vw);
  overflow: auto;
}

.manageEmailTemplates,
.manageDocumentsTable,
.manageQuestionHeight {
  height: calc(100vh - 16.5vw);
  overflow: auto;
}

.manageShifts,
.manageSalaryBenefitHeight,
.hotlistTableHeight {
  height: calc(100vh - 15vw);
  overflow: auto;
}

.manage-email-template {
  height: calc(100vh - 12vw);
  overflow: auto;
}

.manageDocuments th:nth-child(2) {
  width: 10%;
}

.manageDocuments th:first-child,
.manageDocuments th:nth-child(3) {
  width: 15%;
}

.manageDocuments th:nth-child(4) {
  width: 20%;
}

.manageDocuments th:nth-child(5),
.manageDocuments th:nth-child(6),
.manageDocuments th:nth-child(7) {
  width: 8%;
}

/* salary benefits */
.Manage-salary-benefit-proposal th:first-child {
  width: 23%;
}

.Manage-salary-benefit-proposal th:nth-child(2),
.Manage-salary-benefit-proposal th:nth-child(3),
.Manage-salary-benefit-proposal th:nth-child(4),
.Manage-salary-benefit-proposal th:nth-child(5) {
  width: 16%;
}

.manageSalaryBenefitsHeight,
.manageDocumentTypeHeight {
  height: calc(100vh - 16vw);
  overflow: auto;
}

/* Manage todo followup */
.manageTodoFollowUpHeight {
  max-height: calc(100vh - 21vw);
  overflow: auto;
}

.manageTodoFollowUpHeight th:first-child,
.manageTodoFollowUpHeight th:nth-child(7) {
  width: 8%;
}

.manageTodoFollowUpHeight th:nth-child(2),
.manageTodoFollowUpHeight th:nth-child(3),
.manageTodoFollowUpHeight th:nth-child(5),
.manageTodoFollowUpHeight th:nth-child(6) {
  width: 12%;
}

/* Manage contract template */
.manageContractTemplateHeight th:first-child,
.manageContractTemplateHeight th:nth-child(3) {
  width: 15%;
}

.manageContractTemplateHeight th:nth-child(2) {
  width: 25%;
}

.manageContractTemplateHeight th:nth-child(4),
.manageContractTemplateHeight th:nth-child(5) {
  width: 12%;
}

/* Manage temp agency */
.manageTempAgency th {
  width: calc(100% / 5);
}


/* Manage document type */
.manageDocumentType th:not(:last-child) {
  width: 40%;
}

.wage-codes .multiSelectDropdown__control {
  max-height: 5vw !important;
  overflow: auto;
}

.wage-codes .multiSelectDropdown__indicators {
  position: sticky;
  top: 0;
  align-items: self-start;
  height: 100%;
}

/* Manage employee type */
.manageEmployeeTypeHeight th:not(:last-child) {
  width: 40%;
}

.manageSalaryBenefit th {
  width: 25%;
}

/* Hotlist */
.manageHotlistWrapper th:first-child,
.manageHotlistWrapper th:nth-child(2),
.manageHotlistWrapper th:nth-child(3),
.manageHotlistWrapper th:nth-child(4) {
  width: 15%;
}

.manageHotlistWrapper th:nth-child(5),
.manageHotlistWrapper th:nth-child(6) {
  width: 10%;
}

.salaryBenefitPopup .modal-body {
  max-height: calc(100vh - 13vw);
  overflow: auto;
  padding-right: 1.5vw !important;
}

.manageCompetencesHeight {
  height: calc(100vh - 17vw);
  overflow: auto;
}

.manage-competences th{
  width: calc(100% / 4);
}

.configElementsTable {
  height: calc(100vh - 15vw);
  overflow: auto;
}

.busineesUnitCities {
  height: calc(100vh - 16vw);
  overflow: auto;
}

.manageCarSearchCol .FilterMolecule {
  align-self: center;
}