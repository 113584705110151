.pc-coefficient-level {
    height: 2vw !important;
    align-items: center;
}

.pc-coefficient-level .employee-type-coefficient {
    height: 2vw;
}

.defaultCoefficients td {
    height: 2.02vw !important;
    position: relative;
}

.defaultCoefficients td input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}