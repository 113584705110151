.equal-cols {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.equal-cols>[class*='col'] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.equal-cols>[class*='col']>* {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.card-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0vw 0.104vw 0.417vw 0vw;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease;
  border-radius: 0.5vw !important;
}

.recruiter-action-tabs svg {
  width: 2vw;
  height: 2vw;
}

.recruiter-action-tabs .card-shadow:hover,
.tabViewScrollable .card-shadow:hover {
  box-shadow: rgba(0, 165, 206, 0.8) 0vw 0.104vw 0.417vw 0vw;
  background-color: var(--primaryBrandColor);
  color: var(--color-white);
  text-decoration: none !important;
}
.recruiter-action-tabs .card-shadow:hover svg path {
  stroke: var(--color-dark-skyblue) !important;
}
.recruiter-action-tabs .card-shadow:hover .hoverShadow {
  box-shadow: rgb(212 243 249 / 66%) 0vw 0.156vw 0.365vw 0.156vw !important;
  background-color: var(--primaryBrandColor) !important;
}

.name-text {
  font-family: var(--font-fraunces-semibold-italic);
  font-size: var(--size-50px);
  line-height: var(--size-60px);
  color: var(--primaryBrandColor);
}


.profileCardImage {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.available-text {
  bottom: 2vw;
  font-size: var(--size-22px);
  position: relative;
  transform: rotate(-22deg);
  font-family: var(--font-fraunces-semibold-italic);
  color: var(--primaryBrandColor);
}

.candidate-cv-upload,
.matchingCandidateBtn {
  margin-left: 6vw !important;
}

.cvDownloadIcon svg {
  font-size: 1vw;
}

.welcome-message {
  color: var(--color-dark-purple);
  font-size: var(--size-50px);
  font-family: var(--font-roobert-semibold);
  line-height: var(--size-60px);
}

.welcome-text {
  font-size: var(--size-50px);
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-semibold);
  line-height: var(--size-60px);
}

.welcome-message-homepage {
  color: var(--color-dark-purple);
  font-size: var(--size-50px);
  font-family: var(--font-roobert-regular);
  line-height: var(--size-60px);
}

.name-text {
  font-family: var(--font-fraunces-semibold-italic);
  font-size: var(--size-50px);
  line-height: var(--size-60px);
}

.dashboardWrapper .recruiter-action-tabs {
  margin-top: 1vw;
}

/* Budget */

.dashboard-myBudget-wrapper {
  background-color: #F7FDFF !important;
}
.circular-progress-bar-custom {
  width: 9.375vw;
  margin-left: auto;
  margin-right: 8.333vw;
  box-shadow: 0vw 0vw 0.208vw 0.26vw rgb(0 165 206 / 20%);
  border-radius: 50% !important;
  height: 9.375vw;
  position: relative;
}

.CircularProgressbar {
  box-shadow: 0vw 0vw 0.685vw 0.621vw rgb(0 165 206) inset;
  border-radius: 50%;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: var(--sideBarBgColor) !important;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: var(--primaryBrandColor) !important;
  stroke-linecap: square !important;
}

.CircularProgressbar-text {
  fill: var(--color-dark-purple) !important;
  font-size: var(--size-14px) !important;
  font-family: var(--font-roobert-semibold);
}

.circular-progress-bar-custom::after {
  content: attr(data-label);
  position: absolute;
  right: -55%;
  top: 45%;
  width: 35%;
}

.achieved-text,
.circular-progress-bar-custom::after {
  font-size: var(--size-14px);
  color: var(--color-dark-purple);
}

.circular-progress-bar-custom::before {
  content: '';
  position: absolute;
  width: 1.302vw;
  height: 0.26vw;
  background: var(--primaryBrandColor);
  right: -14%;
  top: 50%;
}

.my-budget-title {
  font-size: var(--size-25px);
  color: var(--color-dark-purple);
  font-family: var(--font-roobert-semibold);
  margin-left: 0.5vw !important;
  margin-right: 0.5vw !important;
}

.budgetWrapper>.form-control {
  max-height: 2.344vw !important;
}

.budgetWrapper label {
  display: none;
}

.recruitment-card-num, a .recruitment-card-num {
  font-family: var(--font-roobert-bold);
  font-size: var(--size-25px);
}

.dashboard-tasks-meeting-tabs .nav-link.active, .dashboard-tasks-meeting-tabs .nav-link:hover {
  border-bottom: 0.052vw solid var(--color-dark-skyblue) !important;
  color: var(--color-dark-skyblue) !important;
}

.dashboard-tasks-meeting-tabs .nav-link {
  border-bottom: 0.052vw solid transparent !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  color: var(--color-dark-purple);
  position: relative;
  top: 0.1vw;
}

.dashboard-tasks-meeting-tabs .nav-link {
  margin-left: 2vw;
}

.new-vacancies-cadidates a,
.hoverLine:hover {
  text-decoration: none !important;
}

.saledDashboard svg {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.new-vacancies-cadidates-card {
  padding: 1vw;
  border-radius: 0.4vw !important;
  background-color: var(--sideBarBgColor) !important;
}

.new-vacancies-cadidates-card:hover {
  background-color: var(--color-dark-skyblue) !important;
}

.new-vacancies-cadidates-card .recruitment-card-title {
  margin-bottom: 1vw !important;
}

.new-vacancies-cadidates-card .recruitment-card-num {
  margin-left: 0.25vw !important;
}

.dashboardBannerImage {
  height: 26vw !important;
  border-radius: 0.5vw !important;
  width: 100%;
}

.magicCard {
  padding: 1vw !important;
  border-radius: 0.5vw !important;
}

.dashboard-work-table {
  height: 90% !important;
}

.tasks-meeting-section .tab-content {
  height: 100%;
  position: relative;
}

.tab-pane {
  height: 100%;
}

.link-btn {
  background-color: var(--sideBarBgColor) !important;
  border: .052vw solid var(--sideBarBgColor) !important;
  border-radius: .5vw;
  color: var(--color-dark-skyblue) !important;
  font-size: var(--size-14px) !important;
  padding: .5vw !important;
  transition: all .35s ease;
}

.dashboardGrid {
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;
}

.dashboard-tasks-meeting-tabs {
  height: 1.9vw !important;
}

.dashboard-tasks-meeting-tabs .nav-link {
  padding: 0 0.5vw !important;
}