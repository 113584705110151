.coefficientTableWrapper
  th:not(:first-child):not(:nth-child(2)):not(:nth-child(3)):not(:last-child):not(:nth-child(4)) {
  width: 7vw;
}

.coefficientTableWrapper .coeffDataDynamic label,
.coefficientTableWrapper .coeffDataDynamic .height-20 {
  display: none;
}

.calenderNoInputPosition {
  position: absolute;
  right: 0 !important;
  top: -0.5vw !important;
}

.calenderNoInputPosition span svg {
  position: relative;
  left: 0.05vw;
  top: -0.05vw;
  width: 1.1vw !important;
  height: 1.1vw !important;
}

.calenderNoInputPosition .table-action-btn:hover svg {
  color: var(--color-white) !important;
}
.coefficientTableWrapper th:last-child,
.coefficientTableWrapper td:last-child {
  text-align: center;
}

.coeffDataDynamic {
  padding: 0 !important;
  position: relative;
}

.coeffDataDynamic .col-md-12 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.coeffDataDynamic .col-md-12 input {
  height: 100%;
  border: 0;
  box-shadow: none !important;
}

/* coefficient */
.employee-type .position-relative {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.employee-type .position-relative .form-control {
  width: 100%;
  height: 100%;
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}

.employee-type .position-relative .form-control .multiSelectDropdown__control {
  height: 100%;
}
