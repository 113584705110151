.candidateForm .projectTabs.company-step-tabs-absolute {
  top: -2vw !important;
}

.profileCalendar svg {
  width: 1.25vw !important;
  height: 1.25vw !important;
}

.candidateProfileCard .name-text {
  font-size: 1.5vw !important;
}

.megaPhoneIcon {
  width: 4vw !important;
}

.internalContentWrapper {
  padding: 1vw;
  border-radius: 1vw;
  margin-bottom: 1vw;
  background-color: var(--color-white);
}

.fileUploadFullWidth .file-upload-wrapper {
  width: 100%;
}

.fileUploadFullWidth .file-upload-wrapper .upload-btn {
  padding-left: 0.65vw !important;
  justify-content: start !important;
}

.competentcesAccordionBody {
  padding: 0.5vw !important;
}


.matchingEmployeesTableContainer {
  height: calc(100vh - 27vw);
  overflow: auto;
}

.candidateCornerTabs .card {
  padding: 1.25vw !important;
}

.recruitment-card-title {
  font-size: var(--size-18px);
}

.candidateCornerTabs svg {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

.candidateCornerTabs a:hover {
  text-decoration: none !important;
}

.noInfoText {
  font-size: var(--size-22px);
}

.candidateformAlertClose {
  top: 0.5vw;
  right: 0.5vw;
}

.candidateAutoComplete .col-md-12
{
  position: relative;
}

.candidateAutoComplete .col-md-12 ul {
  position: absolute;
  width: 100%;
}
.cv-upload span {
  font-size: var(--size-15px);
}

.titleNameStyle {
  font-size: var(--size-25px);
  font-family: var(--font-fraunces-semibold-italic);
  color: var(--primaryBrandColor);
}

.candidateScreeningAccordionBody .internalContentWrapper:not(.last-child) {
  margin-bottom: 0 !important;
  padding-bottom: 1vw !important;
}

.candidateScreeningAccordionBody .internalContentWrapper:first-child {
  padding-top: 1vw;
}

.candidateScreeningAccordionBody .internalContentWrapper:not(:first-child) {
  padding-top: 0;
}

.screening-height .accordionScreeningWrapper:last-child  .accordion-item,
.vacancyEmployeementTab .accordion .accordion-item:last-child{
  margin-bottom: 0 !important;
}

.vacancyEmployeementTab .accordion-body {
  padding: 1vw;
}

.manageScreeningTable {
  height: calc(100vh - 23vw);
  overflow: auto;
}

.candidate-follow-up-modal .modal-dialog {
  min-width: 75vw !important;
  width: 75vw !important;
  max-width: 75vw !important;
}