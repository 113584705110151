.managePayments th:nth-child(1),
.managePayments th:nth-child(3),
.managePayments th:nth-child(4),
.managePayments th:nth-child(5),
.managePayments th:nth-child(6),
.managePayments th:nth-child(7) {
  width: 13%;
}

.managePayments th:nth-child(2) {
  width: 15%;
}