.partenaTabs {
  border: 0.052vw solid var(--primaryBrandColor);
}

.partenaTabsActive {
  border: 0.052vw solid var(--primaryBrandColor);
  background-color: var(--primaryBrandColor);
  color: var(--color-white);
  font-family: var(--font-roobert-semibold);
  font-weight: normal !important;
}

.add-partena-modal {
  background: rgba(0, 0, 0, 0.5);
  z-index: 111111;
}

/* .partena-modal-width {
  --bs-modal-width: 1500px;
} */

.partenaMonthPicker .form-control {
  width: 100% !important;
}

.borderBottomStyle {
  border-bottom: 0.052vw solid var(--color-dark-purple);
  padding: 0.25vw;
}

.businessUnitLabel {
  padding: 0.25vw 0.4vw;
}

.businessUnitLabel:hover {
  color: var(--primaryBrandColor) !important;
}

.partenaPopup.modal .btn-close {
  top: -3% !important;
}

.managePartena th:first-child {
  width: 7%;
}

.managePartena th:nth-child(2) {
  width: 10vw !important;
  min-width: 10vw !important;
  max-width: 10vw !important;
}

.managePartena th:nth-child(3),
.managePartena th:nth-child(4),
.managePartena th:nth-child(5),
.managePartena th:nth-child(6),
.managePartena th:nth-child(7) {
  width: 13%;
}

.partenaTableHeight {
  height: calc(100vh - 14.25vw);
  overflow: auto;
}

.partenaCalculationTabs {
  grid-template-columns: repeat(2, 1fr);
}

.partenaCalculationTabs div:first-child {
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
}

.partenaCalculationTabs div:last-child {
  border-bottom-right-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
}

.managePartenaCalculation th {
  width: 20%;
}

.managePartenaEmployeeHeight {
  height: calc(100vh - 21vw);
  overflow: auto;
}

.managePartenaEmployeeCalculation th:first-child {
  width: 15%;
}

.managePartenaPerformanceHeight {
  height: calc(100vh - 19vw);
  overflow: auto;
}

.managePartenaPerformanceCalculation th:first-child,
.managePartenaPerformanceCalculation th:nth-child(3),
.partena-modal-width th:first-child,
.partena-modal-width th:nth-child(3) {
  width: 20%;
}

.managePartenaPerformanceCalculation th:nth-child(2),
.managePartenaPerformanceCalculation th:nth-child(4),
.managePartenaPerformanceCalculation th:nth-child(5),
.managePartenaPerformanceCalculation th:nth-child(6),
.partena-modal-width th:nth-child(2),
.partena-modal-width th:nth-child(4),
.partena-modal-width th:nth-child(5),
.partena-modal-width th:nth-child(6) {
  width: 12%;
}

.partenaCalculationTabs div {
  padding: 0.35vw;
}

.partena-modal-width {
  min-width: 85vw !important;
  width: 85vw !important;
  max-width: 85vw !important;
}

.partenaModalFooter * {
  background: transparent !important;
}

.partenaDetailsPopup {
  height: calc(100vh - 20vw);
  overflow: auto;
}

.partenaDetailsPopup thead {
  position: sticky;
  top: 0;
  background-color: var(--color-white);
  z-index: 999;
}

.partena-modal-width .modal-footer {
  margin-bottom: 0 !important;
  padding-top: 0.8vw !important;
  padding-bottom: 0 !important;
}

.partena-modal-width .table {
  margin-bottom: 0 !important;
}

.partena-modal-width .filterTable th {
  background: transparent !important;
}

.partena-modal-width .modal-body {
  padding-top: 0.5vw !important;
  padding-bottom: 0.5vw !important;
}