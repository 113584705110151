.manage-infaltion-search label,
.manage-infaltion-search .height-20 {
  display: none;
}

.manage-inflation-table tbody td {
  height: 3rem;
}

.manage-inflation-table tbody td .col-md-12 {
  height: 100%;
}

.manage-inflation-table tbody td .col-md-12 > input:focus-visible {
  outline: 1px solid var(--color-dark-purple) !important;
  text-align: center;
}

.manage-inflation-table thead th {
  width: 33.33%;
}

.inflation-date .calender-icon
{
    top: 3px !important;
}

input:disabled{
    background-color: rgb(243, 243, 243)
}