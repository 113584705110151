.accordion-button.collapsed {
  background-color: var(--color-white) !important;
  color: var(--primaryBrandColor) !important;
  border: 0.052vw solid var(--primaryBrandColor) !important;
}

.accordion-button {
  background-color: var(--primaryBrandColor) !important;
  color: var(--color-white) !important;
  box-shadow: none !important;
  border-top-left-radius: 0.5vw !important;
  border-top-right-radius: 0.5vw !important;
  padding: 0.5vw !important;
  font-size: var(--size-16px) !important;
}

.accordion-button:not(.collapsed):after {
  background-image: url('../../../../../static/images/arrow-down.svg') !important;
  background-position: 50%;
  background-size: 1vw !important;
  transform: rotate(180deg);
  width: 1vw !important;
  height: 1vw !important;
}

.accordion-button.accordion-button.collapsed::after {
  background-image: url('../../../../../static/images/arrowDown.svg') !important;
  background-position: 50%;
  background-size: 1vw !important;
  width: 1vw !important;
  height: 1vw !important;
}

.accordion-item .accordion-button.collapsed,
.accordion-item .accordion-collapse {
  border-bottom-left-radius: 0.5vw !important;
  border-bottom-right-radius: 0.5vw !important;
}


.accordion-item {
  margin-bottom: 1vw !important;
  border: 0 !important;
}

.accordion {
  --bs-accordion-bg: transparent !important;
}

.accordion-body {
  background-color: var(--color-white) !important;
  border: 0.052vw solid var(--primaryBrandColor) !important;
  border-bottom-left-radius: 0.5vw !important;
  border-bottom-right-radius: 0.5vw !important;
}

.competentcesAccordionBody .multiSelectDropdown__value-container {
  max-height: 5vw !important;
  overflow: auto;
}

.halfDaROptions {
  line-height: 1vw !important;
}

.leave-count-employer .p-treetable-wrapper {
  height: calc(100vh - 18vw) !important;
  overflow: auto;
}

.colExpand svg {
  width: 1vw !important;
  height: 1vw !important;
}

.leave-count-employer .p-treetable-toggler {
  height: auto !important;
}