.manage-businesss-unit th:not(:last-child) {
  width: 27% !important;
}

/* Candidate */
.Manage-candidates th:first-child {
  width: 7vw;
  min-width: 7vw;
  max-width: 7vw;
}

.Manage-candidates th:nth-child(3) {
  width: 18%;
}

.Manage-candidates th:nth-child(2),
.Manage-candidates th:nth-child(4) {
  width: 10%;
}

.Manage-candidates th:nth-child(5) {
  width: 8%;
}

.Manage-candidates th:nth-child(6) {
  width: 10.5%;
}

.Manage-candidates th:nth-child(7),
.Manage-candidates th:nth-child(8),
.Manage-candidates th:nth-child(9) {
  width: 7.5%;
}

/* Wage proposal */
.wageProposalTable {
  height: calc(100ch - 24.5vw);
  overflow: auto;
}

.Manage-wage-proposal th:first-child {
  width: 15%;
}

.Manage-wage-proposal th:nth-child(2) {
  width: 20%;
}

.Manage-wage-proposal th:nth-child(3) {
  width: 15%;
}

.Manage-wage-proposal th:nth-child(4),
.Manage-wage-proposal th:nth-child(5),
.Manage-wage-proposal th:nth-child(6) {
  width: 10%;
}

.Manage-wage-proposal th:nth-child(7) {
  width: 6%;
}

.Manage-contract-proposal th:first-child {
  width: 10%;
}

.Manage-contract-proposal th:nth-child(2) {
  width: 10%;
}

.Manage-contract-proposal th:nth-child(3) {
  width: 15%;
}

.Manage-contract-proposal th:nth-child(4) {
  width: 18%;
}

.Manage-contract-proposal th:nth-child(5) {
  width: 15%;
}

.Manage-contract-proposal th:nth-child(6) {
  width: 10%;
}

.Manage-contract-proposal th:nth-child(7) {
  width: 10%;
}

.manage-Vacancies th:first-child {
  width: 7vw !important;
  min-width: 7vw !important;
  max-width: 7vw !important;
}

.manage-Vacancies th:nth-child(2),
.manage-Vacancies th:nth-child(3),
.manage-Vacancies th:nth-child(4),
.manage-Vacancies th:nth-child(5),
.manage-Vacancies th:nth-child(6),
.manage-Vacancies th:nth-child(8) {
  width: 10%;
}

.manage-Vacancies th:nth-child(7) {
  width: 8%;
}

.manage-Vacancies th:nth-child(9) {
  width: 5%;
}

.filterGroupedWrapper .d-inline-block:not(:last-child) {
  margin-right: 0.5vw;
}

/*manage timesheet*/
.table_left_side_color_to_be_approved {
  border-left: 4px solid rgb(235, 7, 7);
}

.table_left_side_color_to_be_approved:hover {
  background-color: rgb(235, 7, 7) !important;
  color: white !important;
}

.table_left_side_color_to_be_approved:hover * {
  color: var(--color-white) !important;
}

.table_left_side_color_to_be_invoiced {
  border-left: 4px solid rgb(255, 94, 0);
}

.table_left_side_color_to_be_invoiced:hover {
  background-color: rgb(255, 94, 0) !important;
  color: white !important;
}

.table_left_side_color_to_be_invoiced:hover * {
  color: var(--color-white) !important;
}

.Manage-timesheet table td {
  background-color: unset !important;
}

.Manage-timesheet th:nth-child(2),
.manage-timesheet-two th:first-child {
  width: 7vw !important;
  min-width: 7vw !important;
  max-width: 7vw !important;
}

.Manage-timesheet th:first-child {
  width: 3%;
}

.Manage-timesheet th:nth-child(3),
.Manage-timesheet th:nth-child(8) {
  width: 8%;
}

.Manage-timesheet th:nth-child(4),
.Manage-timesheet th:nth-child(5) {
  width: 15%;
}

.Manage-timesheet th:nth-child(6),
.Manage-timesheet th:nth-child(7) {
  width: 12%;
}

/* Payroll */
.managePayroll th:nth-child(1) {
  width: 35%;
}

.managePayroll th:nth-child(2) {
  width: 20%;
}

/* Refused companies */
.refusedCompanies th:not(:nth-child(4)) {
  width: 18%;
}

/* Manage translations */

.manageTranslationFilters th:nth-child(1),
.manageTranslationFilters th:nth-child(2) {
  width: 35%;
}

/* Invoicing */
.manageInvoicingTable th:nth-child(1) {
  width: 3% !important;
  min-width: 3% !important;
  max-width: 3% !important;
}

.manageInvoicingTable th:nth-child(2) {
  width: 7vw !important;
  max-width: 7vw !important;
  min-width: 7vw !important;
}

.manageInvoicingTable th:nth-child(3) {
  width: 7%;
}

.manageInvoicingTable th:nth-child(8),
.manageInvoicingTable th:nth-child(9) {
  width: 9%;
}

.manageInvoicingTable th:nth-child(6),
.manageInvoicingTable th:nth-child(7) {
  width: 10%;
}

.manageInvoicingTable th:nth-child(4) {
  width: 10%;
}

.manageInvoicingTable th:nth-child(5) {
  width: 13%;
}

.manageInvoicingTable th:nth-child(9) {
  width: 11%;
}

.manageInvoicingTable th:nth-child(10) {
  width: 8%;
}

.manage-timesheet-two th:nth-child(2),
.manage-timesheet-two th:nth-child(3) {
  width: 15%;
}
.manage-timesheet-two th:nth-child(4),
.manage-timesheet-two th:nth-child(5) {
  width: 25%;
}